import { MutableRefObject } from "react";
import { PauseCircleOutline, TrendingUp } from "@mui/icons-material";
import AssignmentIcon from "@mui/icons-material/Assignment";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ReportIcon from "@mui/icons-material/Report";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import { IconButton } from "@mui/material";
import {
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
} from "@mui/x-data-grid-premium";
import { QueryClient } from "@tanstack/react-query";
import {
  Automation,
  AutomationTaskStatus,
  TableColumnFormat,
} from "../../../../graphql/operations";
import Status from "../../../../shared/components/Status";
import { Paused, Running } from "../../../../shared/components/Svg";
import { getTableColumn } from "../../../../shared/components/Table";
import {
  snakeCaseToCapitalizedCaseValueFormatter,
  columnTimezoneDateTimeFormatParams,
} from "../../../../utils";
import { DataAutomationTask } from "./AutomationTasks";

export enum AutomationsDrawerState {
  edit = "edit",
  create = "create",
}

export const setSelectedAutomation = (
  data: GridRowParams,
  setAutomationToEdit: Function,
  setFormMode: Function,
  setDrawerOpened: Function
) => {
  if (data?.row) {
    setAutomationToEdit(data.row);
    setFormMode(AutomationsDrawerState.edit);
    setDrawerOpened(true);
  }
};

export const handleRefresh = (
  queryClient: QueryClient,
  refreshAutomations: MutableRefObject<Function>
) => {
  queryClient.invalidateQueries({ queryKey: ["getAutomations"] });
  refreshAutomations?.current();
};

export const automationColumns: GridColDef<Automation>[] = [
  {
    field: "name",
    headerName: "Automation name",
    flex: 1,
    minWidth: 120,
  },
  {
    field: "type",
    headerName: "Trigger Type",
    flex: 1,
    minWidth: 120,
    valueFormatter: snakeCaseToCapitalizedCaseValueFormatter,
  },
  {
    field: "action",
    headerName: "Action",
    flex: 1,
    minWidth: 120,
  },
  {
    field: "lastRun",
    headerName: "Last run",
    flex: 1,
    minWidth: 120,
  },
  {
    field: "status",
    headerName: "Status",
    flex: 1,
    minWidth: 120,
    renderCell: (params) => getStatus(params.row.status),
  },
  {
    field: "actionButtons",
    headerName: "Action Buttons",
    minWidth: 120,
    groupable: false,
    renderCell: (params: GridRenderCellParams) => {
      const { colorReflectsAsset, outerRingReflectsDwell } = params.row;

      return (
        <>
          <IconButton aria-label="close" onClick={() => {}}>
            <PauseCircleOutline />
          </IconButton>
          <IconButton aria-label="close" onClick={() => {}}>
            <TrendingUp />
          </IconButton>
        </>
      );
    },
    disableExport: true,
    filterable: false,
  },
];

export const getStatus = (status?: string) => {
  switch (status) {
    case "Paused":
      return (
        <Status
          bgColor="bg-concrete"
          icon={<Paused />}
          text="Paused"
          maxWidth={103}
          textColor="mid-charcoal"
          iconColor="mid-charcoal"
        />
      );
    case "Running":
      return (
        <Status
          bgColor="bg-green"
          icon={<Running />}
          text="Running"
          maxWidth={103}
        />
      );
    default:
      return (
        <Status
          bgColor="bg-concrete"
          icon={<Paused />}
          text="Paused"
          maxWidth={103}
          textColor="mid-charcoal"
          iconColor="mid-charcoal"
        />
      );
  }
};

export const automationsVisibleColumns = {
  name: true,
  trigger: true,
  action: true,
  lastRun: true,
  status: true,
  actionButton: true,
};

export const searchKeys = [
  "name",
  "trigger",
  "action",
  "lastRun",
  "status",
  "actionButton",
];
export const TaskSearchKeys = [
  "imei",
  "assetIdentifier",
  "startedAt",
  "completedAt",
];

export const getAutomationTaskColumns = (
  timezone: string,
  handleOpenTroubleshooting: (data: DataAutomationTask) => void
) => {
  const columns = [
    {
      field: "imei",
      headerName: "Device ID",
    },
    {
      field: "assetIdentifier",
      headerName: "Asset ID",
    },
    {
      field: "startedAt",
      headerName: "Start Time",
      format: TableColumnFormat.IsoDateTime,
      options: { ...columnTimezoneDateTimeFormatParams(timezone) },
    },
    {
      field: "completedAt",
      headerName: "End Time",
      format: TableColumnFormat.IsoDateTime,
      options: { ...columnTimezoneDateTimeFormatParams(timezone) },
    },
    {
      field: "status",
      headerName: "Status",
      options: {
        renderCell: (params: GridRenderCellParams) => {
          switch (params.formattedValue) {
            case AutomationTaskStatus.Active: {
              return (
                <>
                  <div className=" bg-success-transparent-80 px-4 py-1 rounded">
                    <TrendingUpIcon className="text-white mr-2" />
                    <span className="text-xs font-bold text-white">
                      Running
                    </span>
                  </div>
                </>
              );
            }
            case AutomationTaskStatus.Completed: {
              return (
                <>
                  <div className=" bg-breadcrumbs-table-moving-with-primary-voltage px-4 py-1 rounded">
                    <CheckCircleIcon className="text-mid-charcoal mr-2" />
                    <span className="text-xs font-bold text-mid-charcoal">
                      Completed
                    </span>
                  </div>
                </>
              );
            }
            case AutomationTaskStatus.Failed: {
              return (
                <>
                  <div className=" bg-error-transparent-80 px-4 py-1 rounded">
                    <ReportIcon className="text-white mr-2" />
                    <span className="text-xs font-bold text-white">Failed</span>
                  </div>
                </>
              );
            }
            default: {
              return "";
            }
          }
        },
      },
    },
    {
      field: "debugData",
      headerName: "Debug Info",

      options: {
        groupable: false,
        filterable: false,
        sortable: false,
        disableExport: true,
        renderCell: (params: GridRenderCellParams) => {
          const dataRow: DataAutomationTask = {
            id: params.row.id,
            deviceId: params.row.imei,
            assetId: params.row.assetIdentifier,
            triggerInformation: params.row.triggerInformation ?? "-",
          };
          return (
            <>
              <IconButton
                aria-label="close"
                onClick={() => handleOpenTroubleshooting(dataRow)}
              >
                <AssignmentIcon />
              </IconButton>
            </>
          );
        },
      },
    },
  ];
  return columns.map(getTableColumn);
};
