import { FieldValues, UseFormReturn } from "react-hook-form";
import { QueryClient } from "@tanstack/react-query";
import parse from "parse-duration";
import slug from "slug";
import * as yup from "yup";
import { PAGE_SNACKBAR } from "../../constants";
import {
  AlertSchedule,
  AlertType,
  AlertTypeV2,
  AlertV2,
  FindAssetsByIdsQuery,
  FindGeofencesByIdsQuery,
  FindAssetsByIdsForSharingOsQuery,
  Maybe,
  FindAssetsByIdsOsQuery,
} from "../../graphql/operations";
import {
  mapServerErrorCodeToHumanReadableMessage,
  validateAlertScheduleTimeRange,
} from "../../utils";
import {
  checkIsBefore,
  getDurationFromMillis,
  parseISODate,
} from "../../utils/date";
import { NavigationRoutes } from "../../utils/routes/routesUtils";
import { isSubscribersSelected } from "../ReportView/AssetReport/components/AssetReportForm/hooks/useScheduleForm";
import {
  convertTimeTo12Hour,
  convertTimeTo24Hour,
} from "../ReportView/helpers/helpers";
import { AlertTemplateData } from "./components/AlertTemplate";
import {
  AlertFormValues,
  DwellAlertFormValues,
  SpeedAlertFormValues,
} from "./interfaces";

const commonAlertSchedule = {
  startDate: "",
  endDate: "",
  timeFrom: "",
  timeFormatFrom: "",
  timeTo: "",
  timeFormatTo: "",
  days: [],
  suspended: true,
  props: {
    receiveWhenTriggerActive: false,
    sendWhenParked: false,
    sendWhenActive: false,
  },
};
const commonAlertSubscribers = { roles: [], users: [], emails: [] };
const commonAlertTopLevelFields = {
  _id: "",
  addedAt: null,
  orgId: "",
  orgName: "",
  updatedAt: null,
  message: "",
  incidentsCount: 0,
  escalationUsers: [],
  escalationTime: { days: 0, hours: 0, minutes: 0 },
};

export const buildGeofenceAlertTemplate = (templateDate: AlertTemplateData) => {
  return {
    type: AlertType.Geofence,
    ...commonAlertTopLevelFields,
    ...templateDate,
    name: templateDate.name ?? "Geofence",
    parameters: {
      geofenceType: "",
      geofence: "",
      alertOnEnter: false,
      alertOnExit: false,
    },
    reminder: {
      schedule: {
        ...commonAlertSchedule,
      },
      subscribers: { ...commonAlertSubscribers },
    },
  } as unknown as AlertV2;
};
export const buildCargoAlertTemplate = (templateDate: AlertTemplateData) => {
  return {
    type: AlertType.Cargo,
    ...commonAlertTopLevelFields,
    ...templateDate,
    name: templateDate.name ?? "Cargo",
    parameters: {
      assetIds: [],
      assetType: "",
      isEmpty: false,
      isFull: false,
    },
    reminder: {
      schedule: {
        ...commonAlertSchedule,
      },
      subscribers: { ...commonAlertSubscribers },
    },
  } as unknown as AlertV2;
};

export const buildAirTankAlertTemplate = (
  templateDate: AlertTemplateData
): AlertV2 => {
  return {
    type: AlertTypeV2.AirTank,
    ...commonAlertTopLevelFields,
    ...templateDate,
    name: templateDate.name ?? "Air Tank",
    parameters: {
      airTankHealthState: "",
    },
    reminder: {
      schedule: commonAlertSchedule,
      subscribers: commonAlertSubscribers,
    },
  };
};

export const buildTPMSAlertTemplate = (
  templateDate: AlertTemplateData
): AlertV2 => {
  return {
    type: AlertTypeV2.TirePressureMonitorSystem,
    ...commonAlertTopLevelFields,
    ...templateDate,
    name: templateDate.name ?? "TPMS",
    parameters: {
      tpmsHealthStatus: "",
    },
    reminder: {
      schedule: commonAlertSchedule,
      subscribers: commonAlertSubscribers,
    },
  };
};

export const buildATISAlertTemplate = (
  templateDate: AlertTemplateData
): AlertV2 => {
  return {
    type: AlertTypeV2.Atis,
    ...commonAlertTopLevelFields,
    ...templateDate,
    name: templateDate.name ?? "ATIS",
    parameters: {
      assetIds: [],
      assetType: "",
      atisHealthStatus: "",
    },
    reminder: {
      schedule: commonAlertSchedule,
      subscribers: commonAlertSubscribers,
    },
  };
};

export const buildDwellAlertTemplate = (templateDate: AlertTemplateData) => {
  return {
    type: AlertType.Dwell,
    ...commonAlertTopLevelFields,
    ...templateDate,
    name: templateDate.name ?? "Dwell",
    parameters: {
      assetIds: [],
      assetType: "",
      insideGeofence: false,
      outsideGeofence: false,
      dwellThreshold: "",
    },
    reminder: {
      schedule: {
        ...commonAlertSchedule,
      },
      subscribers: { ...commonAlertSubscribers },
    },
  } as unknown as AlertV2;
};
export const buildCustomAlertTemplate = (templateDate: AlertTemplateData) => {
  return {
    type: AlertType.Custom,
    ...commonAlertTopLevelFields,
    ...templateDate,
    name: templateDate.name ?? "Custom",
    reminder: {
      schedule: {
        ...commonAlertSchedule,
      },
      subscribers: { ...commonAlertSubscribers },
    },
  } as unknown as AlertV2;
};
export const buildSpeedAlertTemplate = (templateDate: AlertTemplateData) => {
  return {
    type: AlertType.Speeding,
    ...commonAlertTopLevelFields,
    ...templateDate,
    name: templateDate.name ?? "Speeding",
    parameters: {
      speed: "",
    },
    reminder: {
      schedule: {
        ...commonAlertSchedule,
      },
      subscribers: { ...commonAlertSubscribers },
    },
  } as unknown as AlertV2;
};

export const buildSensorHealthAlertTemplate = (
  templateDate: AlertTemplateData
): AlertV2 => {
  return {
    type: AlertTypeV2.SensorHealth,
    ...commonAlertTopLevelFields,
    ...templateDate,
    name: templateDate.name ?? "Sensor Health",
    parameters: {
      assetIds: [],
      assetType: "",
      sensorHealthStatus: "",
    },
    reminder: {
      schedule: commonAlertSchedule,
      subscribers: commonAlertSubscribers,
    },
  };
};

export const buildTpmsCatastrophicAlertTemplate = (
  templateDate: AlertTemplateData
): AlertV2 => {
  return {
    type: AlertTypeV2.TirePressureMonitorSystemCatastrophic,
    ...commonAlertTopLevelFields,
    ...templateDate,
    name: templateDate.name ?? "TPMS Catastrophic",
    parameters: {
      assetIds: [],
      assetType: "",
      pressureThreshold: 0,
    },
    reminder: {
      schedule: commonAlertSchedule,
      subscribers: commonAlertSubscribers,
    },
  };
};

export const buildMovingWithoutPrimOrSecPowerAlertTemplate = (
  templateDate: AlertTemplateData
): AlertV2 => {
  return {
    type: AlertTypeV2.MovingWithoutPrimaryOrSecondaryPower,
    ...commonAlertTopLevelFields,
    ...templateDate,
    name: templateDate.name ?? "Moving without Primary or Secondary Power",
    parameters: {
      assetIds: [],
      assetType: "",
    },
    reminder: {
      schedule: commonAlertSchedule,
      subscribers: commonAlertSubscribers,
    },
  };
};
export const buildWheelEndAlertTemplate = (
  templateDate: AlertTemplateData
): AlertV2 => {
  return {
    type: AlertTypeV2.WheelEnd,
    ...commonAlertTopLevelFields,
    ...templateDate,
    name: templateDate.name ?? "Wheel End",
    parameters: {
      assetIds: [],
      assetType: "",
      wheelEndHealthState: "",
    },
    reminder: {
      schedule: commonAlertSchedule,
      subscribers: commonAlertSubscribers,
    },
  };
};
export const buildLightCircuitAlertTemplate = (
  templateDate: AlertTemplateData
): AlertV2 => {
  return {
    type: AlertTypeV2.LightCircuit,
    ...commonAlertTopLevelFields,
    ...templateDate,
    name: templateDate.name ?? "Light Circuit",
    parameters: {
      assetIds: [],
      assetType: "",
      lightsHealthStatus: "",
    },
    reminder: {
      schedule: commonAlertSchedule,
      subscribers: commonAlertSubscribers,
    },
  };
};

export const buildMetricsAlertTemplate = (templateDate: AlertTemplateData) => {
  return {
    type: AlertTypeV2.Metrics,
    ...commonAlertTopLevelFields,
    ...templateDate,
    name: "Metrics",
    parameters: {},
    reminder: {
      schedule: commonAlertSchedule,
      subscribers: commonAlertSubscribers,
    },
  };
};

export const buildDoorOpenOutsideOfGeofenceAlertTemplate = (
  templateDate: AlertTemplateData
): AlertV2 => {
  return {
    type: AlertTypeV2.DoorOpenOutsideOfGeofence,
    ...commonAlertTopLevelFields,
    ...templateDate,
    name: templateDate.name ?? "Door Open Outside Of Geofence",
    parameters: {
      assetIds: [],
      assetType: "",
    },
    reminder: {
      schedule: commonAlertSchedule,
      subscribers: commonAlertSubscribers,
    },
  };
};

export const buildReeferAlertTemplate = (
  templateDate: AlertTemplateData
): AlertV2 => {
  return {
    type: AlertTypeV2.Reefer,
    ...commonAlertTopLevelFields,
    ...templateDate,
    name: templateDate.name ?? "Reefer",
    parameters: {
      reeferTemperatureHealthStatus: "",
    },
    reminder: {
      schedule: commonAlertSchedule,
      subscribers: commonAlertSubscribers,
    },
  };
};

export const buildDoorOpenAndMovingTemplate = (
  templateDate: AlertTemplateData
): AlertV2 => {
  return {
    type: AlertTypeV2.DoorOpenAndMoving,
    ...commonAlertTopLevelFields,
    ...templateDate,
    name: templateDate.name ?? "Door Open And Moving",
    parameters: {
      assetIds: [],
      assetType: "",
    },
    reminder: {
      schedule: commonAlertSchedule,
      subscribers: commonAlertSubscribers,
    },
  };
};

export const buildATISNotFunctioningTemplate = (
  templateDate: AlertTemplateData
): AlertV2 => {
  return {
    type: AlertTypeV2.AtisNotFunctioning,
    ...commonAlertTopLevelFields,
    ...templateDate,
    name: templateDate.name ?? "ATIS Not Functioning",
    parameters: {
      assetIds: [],
      assetType: "",
    },
    reminder: {
      schedule: commonAlertSchedule,
      subscribers: commonAlertSubscribers,
    },
  };
};
export const buildABSFaultAlertTemplate = (
  templateDate: AlertTemplateData
): AlertV2 => {
  return {
    type: AlertTypeV2.AbsFault,
    ...commonAlertTopLevelFields,
    ...templateDate,
    name: templateDate.name ?? "ABS Fault",
    parameters: {
      assetIds: [],
      assetType: "",
      absNumberOfFaults: "0",
    },
    reminder: {
      schedule: commonAlertSchedule,
      subscribers: commonAlertSubscribers,
    },
  };
};

export const buildTemperatureTemplate = (
  templateDate: AlertTemplateData
): AlertV2 => {
  return {
    type: AlertTypeV2.Temperature,
    ...commonAlertTopLevelFields,
    ...templateDate,
    name: templateDate.name ?? "Temperature",
    parameters: {
      temperatureHealthStatus: "",
      assetIds: [],
      assetType: "",
    },
    reminder: {
      schedule: commonAlertSchedule,
      subscribers: commonAlertSubscribers,
    },
  };
};

export const buildUintIntervalOptions = (unit: string) => {
  const options = [];
  switch (unit) {
    case "minutes":
      for (let i = 5; i < 60; i += 5) {
        options.push({ value: `${i}`, label: `${i}` });
      }
      break;
    case "hours":
      for (let i = 1; i <= 23; i++) {
        options.push({ value: `${i}`, label: `${i}` });
      }
      break;
    case "days":
      for (let i = 1; i <= 365; i++) {
        options.push({ value: `${i}`, label: `${i}` });
      }
      break;
    default:
      break;
  }
  return options;
};

export const checkTopLevelSchedule = (parent: any) => {
  if (parent.reminder.schedule.timeFrom) {
    return (
      !!parent.reminder.schedule.timeTo &&
      !!parent.reminder.schedule.timeFormatTo &&
      !!parent.reminder.schedule.timeFormatFrom &&
      !!parent.reminder.schedule.days.length
    );
  } else if (parent.reminder.schedule.timeTo) {
    return (
      !!parent.reminder.schedule.timeFrom &&
      !!parent.reminder.schedule.timeFormatTo &&
      !!parent.reminder.schedule.timeFormatFrom &&
      !!parent.reminder.schedule.days.length
    );
  } else if (parent.reminder.schedule.timeFormatTo) {
    return (
      !!parent.reminder.schedule.timeFrom &&
      !!parent.reminder.schedule.timeTo &&
      !!parent.reminder.schedule.timeFormatFrom &&
      !!parent.reminder.schedule.days.length
    );
  } else if (parent.reminder.schedule.timeFormatFrom) {
    return (
      !!parent.reminder.schedule.timeFrom &&
      !!parent.reminder.schedule.timeTo &&
      !!parent.reminder.schedule.timeFormatTo &&
      !!parent.reminder.schedule.days.length
    );
  } else if (parent.reminder.schedule.days.length) {
    return (
      !!parent.reminder.schedule.timeFrom &&
      !!parent.reminder.schedule.timeTo &&
      !!parent.reminder.schedule.timeFormatTo &&
      !!parent.reminder.schedule.timeFormatFrom
    );
  }
  return true;
};
export const checkSchedule = (parent: any) => {
  if (parent.timeFrom) {
    return (
      !!parent.timeTo &&
      !!parent.timeFormatTo &&
      !!parent.timeFormatFrom &&
      !!parent.days.length
    );
  } else if (parent.timeTo) {
    return (
      !!parent.timeFrom &&
      !!parent.timeFormatTo &&
      !!parent.timeFormatFrom &&
      !!parent.days.length
    );
  } else if (parent.timeFormatTo) {
    return (
      !!parent.timeFrom &&
      !!parent.timeTo &&
      !!parent.timeFormatFrom &&
      !!parent.days.length
    );
  } else if (parent.timeFormatFrom) {
    return (
      !!parent.timeFrom &&
      !!parent.timeTo &&
      !!parent.timeFormatTo &&
      !!parent.days.length
    );
  } else if (parent.days.length) {
    return (
      !!parent.timeFrom &&
      !!parent.timeTo &&
      !!parent.timeFormatTo &&
      !!parent.timeFormatFrom
    );
  }
  return true;
};

export const checkReminders = (parent: any) => {
  const {
    unit,
    interval,
    props: { receiveWhenTriggerActive, sendWhenParked, sendWhenActive },
  } = parent;
  try {
    return !!checkRemindersSchema.validateSync({
      unit,
      interval,
      receiveWhenTriggerActive,
      sendWhenParked,
      sendWhenActive,
    });
  } catch (e) {
    return false;
  }
};

const checkRemindersSchema = yup.object().shape(
  {
    unit: yup
      .string()
      .nullable()
      .when(
        [
          "receiveWhenTriggerActive",
          "sendWhenParked",
          "sendWhenActive",
          "interval",
        ],
        {
          is: (
            receiveWhenTriggerActive: boolean,
            sendWhenParked: boolean,
            sendWhenActive: boolean,
            interval: string
          ) =>
            receiveWhenTriggerActive ||
            sendWhenParked ||
            sendWhenActive ||
            interval,
          then: (scheme) => scheme.required(),
        }
      ),
    interval: yup
      .string()
      .nullable()
      .when(
        [
          "receiveWhenTriggerActive",
          "sendWhenParked",
          "sendWhenActive",
          "unit",
        ],
        {
          is: (
            receiveWhenTriggerActive: boolean,
            sendWhenParked: boolean,
            sendWhenActive: boolean,
            unit: string
          ) =>
            receiveWhenTriggerActive ||
            sendWhenParked ||
            sendWhenActive ||
            unit,
          then: (scheme) => scheme.required(),
        }
      ),
    receiveWhenTriggerActive: yup.boolean(),
    sendWhenParked: yup.boolean(),
    sendWhenActive: yup.boolean(),
  },
  [["interval", "unit"]]
);

export const getAlertCommonSchema = () => {
  return {
    escalationTime: yup.object().shape({
      days: yup.number().nullable(),
      hours: yup.number().nullable(),
      minutes: yup.number().nullable(),
    }),
    escalationTimeSummary: yup
      .string()
      .nullable()
      .when("saveAsNewAlertsType", {
        is: (value: boolean) => !value,
        then: yup
          .string()
          .nullable()
          .test(
            "escalationUsersWasSelected",
            "Escalate After is required",
            (value, { parent }) => {
              if (
                parent.escalationUsers.length &&
                parent.escalationTime.days === 0 &&
                parent.escalationTime.hours === 0 &&
                parent.escalationTime.minutes === 0
              ) {
                return false;
              } else {
                return true;
              }
            }
          )
          .test(
            "shouldBePositive",
            "Escalate After should be greater than 0",
            (value, { parent }) => {
              if (
                (parent.escalationTime.days &&
                  parent.escalationTime.days < 0) ||
                (parent.escalationTime.hours &&
                  parent.escalationTime.hours < 0) ||
                (parent.escalationTime.minutes &&
                  parent.escalationTime.minutes < 0)
              ) {
                return false;
              }
              return true;
            }
          )
          .test(
            "shouldBeLessThan",
            "Escalate After should be less than 999",
            (value, { parent }) => {
              if (
                (parent.escalationTime.days &&
                  parent.escalationTime.days > 999) ||
                (parent.escalationTime.hours &&
                  parent.escalationTime.hours > 999) ||
                (parent.escalationTime.minutes &&
                  parent.escalationTime.minutes > 999)
              ) {
                return false;
              }
              return true;
            }
          ),
      }),
    escalationUsers: yup
      .array()
      .of(yup.string())
      .when("saveAsNewAlertsType", {
        is: (value: boolean) => !value,
        then: yup
          .array()
          .of(yup.string())
          .test(
            "escalationTimeWasSet",
            "Escalation Users are required",
            (value, { parent }) => {
              if (
                parent.escalationTime.days ||
                parent.escalationTime.hours ||
                parent.escalationTime.minutes
              ) {
                return !!value?.length;
              } else {
                return true;
              }
            }
          ),
      }),
    saveAsNewAlertsType: yup.boolean(),
    alertTemplateOrgIds: yup
      .array()
      .of(yup.string())
      .when("saveAsNewAlertsType", {
        is: true,
        then: (scheme) => scheme.min(1, "Organization is required"),
      }),
    scheduleSummary: yup.string().when("saveAsNewAlertsType", {
      is: (value: boolean) => !value,
      then: yup
        .string()
        .test(
          "scheduleWasFilled",
          "Time and working days are required",
          (value, { parent }) => {
            return checkTopLevelSchedule(parent);
          }
        )
        .test(
          "date-not-in-past",
          "Please select End Date not in the past",
          (value, { parent }) => {
            if (!parent.reminder.schedule.endDate) return true;
            return !checkIsBefore(
              new Date(parent.reminder.schedule.endDate),
              new Date()
            );
          }
        )
        .test(
          "date-not-before-start",
          "End Date should be after the Start Date",
          (value, { parent }) => {
            if (!parent.reminder.schedule.endDate) return true;
            return !checkIsBefore(
              new Date(parent.reminder.schedule.endDate),
              new Date(parent.reminder.schedule.startDate)
            );
          }
        )
        .test(
          "time-from-not-after-time-to",
          "Time from should be before the Time to",
          (value, { parent }) => {
            if (!parent?.reminder?.schedule) return true;
            return validateAlertScheduleTimeRange(parent?.reminder?.schedule);
          }
        ),
    }),
    remindersSummary: yup.string().when("saveAsNewAlertsType", {
      is: (value: boolean) => !value,
      then: yup
        .string()
        .test(
          "remindersWasFilled",
          "Unit and Interval are required",
          (value, { parent }) => {
            return checkReminders(parent.reminder.schedule);
          }
        ),
    }),
    message: yup.string().nullable().max(1000, "Max 1000 characters"),
    reminders: yup.array().of(
      yup.object().shape({
        schedule: yup.object().shape({
          startDate: yup.string().nullable(),
          endDate: yup
            .string()
            .nullable()
            .test(
              "date-not-in-past",
              "Please select End Date not in the past",
              (value, form: Record<string, any>) => {
                if (!value) return true;
                if (form.from[2].value.saveAsNewAlertsType) return true;
                return !checkIsBefore(new Date(value), new Date());
              }
            )
            .test(
              "date-not-before-start",
              "End Date should be after the Start Date",
              (value, form: Record<string, any>) => {
                if (!value) return true;
                if (form.from[2].value.saveAsNewAlertsType) return true;
                return !checkIsBefore(
                  new Date(value),
                  new Date(form.parent.startDate)
                );
              }
            ),
          timeFrom: yup
            .string()
            .test(
              "timeWasFilled",
              "Time From is required",
              (value, form: Record<string, any>) => {
                if (form.from[2].value.saveAsNewAlertsType) return true;
                return checkSchedule(form.parent);
              }
            )
            .test(
              "timeIsValid",
              "Time from should be before the Time to",
              (value, form: Record<string, any>) => {
                if (form.from[2].value.saveAsNewAlertsType) return true;
                return validateAlertScheduleTimeRange(form.parent);
              }
            ),
          timeFormatFrom: yup
            .string()
            .test(
              "timeFormatWasFilled",
              "Time format is required",
              (value, form: Record<string, any>) => {
                if (form.from[2].value.saveAsNewAlertsType) return true;
                return checkSchedule(form.parent);
              }
            )
            .test(
              "timeIsValid",
              "Time from should be before the Time to",
              (value, form: Record<string, any>) => {
                if (form.from[2].value.saveAsNewAlertsType) return true;
                return validateAlertScheduleTimeRange(form.parent);
              }
            ),
          timeTo: yup
            .string()
            .test(
              "timeWasFilled",
              "Time To is required",
              (value, form: Record<string, any>) => {
                if (form.from[2].value.saveAsNewAlertsType) return true;
                return checkSchedule(form.parent);
              }
            )
            .test(
              "timeIsValid",
              "Time to should be after the Time from",
              (value, form: Record<string, any>) => {
                if (form.from[2].value.saveAsNewAlertsType) return true;
                return validateAlertScheduleTimeRange(form.parent);
              }
            ),
          timeFormatTo: yup
            .string()
            .test(
              "timeFormatWasFilled",
              "Time format is required",
              (value, form: Record<string, any>) => {
                if (form.from[2].value.saveAsNewAlertsType) return true;
                return checkSchedule(form.parent);
              }
            )
            .test(
              "timeIsValid",
              "Time to should be after the Time from",
              (value, form: Record<string, any>) => {
                if (form.from[2].value.saveAsNewAlertsType) return true;
                return validateAlertScheduleTimeRange(form.parent);
              }
            ),
          days: yup
            .array(yup.string())
            .test(
              "daysWasFilled",
              "Working days are required",
              (value, form: Record<string, any>) => {
                if (form.from[2].value.saveAsNewAlertsType) return true;
                return checkSchedule(form.parent);
              }
            ),
          interval: yup
            .string()
            .nullable()
            .test(
              "intervalWasFilled",
              "Interval is required",
              (value, form: Record<string, any>) => {
                if (form.from[2].value.saveAsNewAlertsType) return true;
                return checkReminders(form.parent);
              }
            ),
          unit: yup
            .string()
            .nullable()
            .test(
              "unitWasFilled",
              "Unit is required",
              (value, form: Record<string, any>) => {
                if (form.from[2].value.saveAsNewAlertsType) return true;
                return checkReminders(form.parent);
              }
            ),
          props: yup.object().shape({
            receiveWhenTriggerActive: yup.boolean(),
            sendWhenParked: yup.boolean(),
            sendWhenActive: yup.boolean(),
          }),
        }),
        subscribers: yup.object().shape({
          roles: yup
            .array(yup.string())
            .test(
              "anyOfRoles",
              "At least one recipient should be selected",
              (value, form: Record<string, any>) => {
                if (form.from[2].value.saveAsNewAlertsType) return true;
                return isSubscribersSelected(form.parent);
              }
            ),
          users: yup
            .array(yup.string())
            .test(
              "anyOfEmails",
              "At least one recipient should be selected",
              (value, form: Record<string, any>) => {
                if (form.from[2].value.saveAsNewAlertsType) return true;
                return isSubscribersSelected(form.parent);
              }
            ),
          emails: yup
            .array(yup.string())
            .test(
              "anyOfEmails",
              "At least one recipient should be selected",
              (value, form: Record<string, any>) => {
                if (form.from[2].value.saveAsNewAlertsType) return true;
                return isSubscribersSelected(form.parent);
              }
            ),
        }),
      })
    ),
  };
};

export const dwellCargoCommonSchema = {
  assetIds: yup
    .array()
    .of(
      yup.object().shape({
        id: yup.string(),
        label: yup.string(),
      })
    )
    .optional(),
  assetType: yup.string().nullable().optional(),
};

export const metricsSchema = {
  metric: yup.string().required("Metric is required"),
  operator: yup.string().required("Operator is required"),
  value: yup
    .number()
    .transform((value, originalValue) => (originalValue === "" ? null : value))
    .nullable()
    .required("Value is required"),
  startDate: yup.string().required("Interval is required"),
  endDate: yup.string().required("Interval is required"),
};

export const buildRegulatorAlertTemplate = (
  templateDate: AlertTemplateData
): AlertV2 => {
  return {
    type: AlertTypeV2.Regulator,
    ...commonAlertTopLevelFields,
    ...templateDate,
    name: templateDate.name ?? "Regulator",
    parameters: {
      assetIds: [],
      assetType: "",
      regulatorHealthStatus: "",
    },
    reminder: {
      schedule: commonAlertSchedule,
      subscribers: commonAlertSubscribers,
    },
  };
};

export enum MetricAlertOptions {
  PrimaryBatteryVoltage = "primary_battery_voltage",
  SecondaryBatteryVoltage = "secondary_battery_voltage",
  SolarBatteryVoltage = "solar_battery_voltage",
  BatteryVoltage = "battery_voltage",
  Odometer = "odometer",
}

export const metricsOptions = [
  {
    id: MetricAlertOptions.PrimaryBatteryVoltage,
    label: "Primary Battery Voltage",
  },
  {
    id: MetricAlertOptions.SecondaryBatteryVoltage,
    label: "Secondary Battery Voltage",
  },
  {
    id: MetricAlertOptions.SolarBatteryVoltage,
    label: "Solar Battery Voltage",
  },
  {
    id: MetricAlertOptions.BatteryVoltage,
    label: "Battery Voltage",
  },
  {
    id: MetricAlertOptions.Odometer,
    label: "Odometer",
  },
];

export const operatorOptions = [
  {
    id: ">",
    label: "Greater than",
  },
  {
    id: "<",
    label: "Less than",
  },
  {
    id: "==",
    label: "Equal to",
  },
  {
    id: ">=",
    label: "Greater than or equal to",
  },
  {
    id: "<=",
    label: "Less than or equal to",
  },
];

export const getAlertParametersByType = (
  alertType: string,
  speedValueUnit: string,
  data: FieldValues
) => {
  switch (alertType) {
    case AlertType.Geofence: {
      const geofenceParameters = data?.parameters || {};
      // If both parameters are unchecked, then alertOnEnter and alertOnExit should be true
      const alertOnEnter =
        !geofenceParameters.alertOnEnter && !geofenceParameters.alertOnExit
          ? true
          : geofenceParameters.alertOnEnter;
      const alertOnExit =
        !geofenceParameters.alertOnEnter && !geofenceParameters.alertOnExit
          ? true
          : geofenceParameters.alertOnExit;
      return {
        geofenceIds:
          geofenceParameters.geofenceIds?.map((geofence: any) => geofence.id) ??
          null,
        geofenceType: geofenceParameters.geofenceType || null,
        region: geofenceParameters.region ?? [],
        zone: geofenceParameters.zone ?? [],
        alertOnEnter: alertOnEnter,
        alertOnExit: alertOnExit,
      };
    }
    case AlertType.Cargo: {
      const cargoParameters = data?.parameters || {};
      // If both parameters are unchecked, then isEmpty and isFull should be true
      const isEmpty =
        !cargoParameters.isEmpty && !cargoParameters.isFull
          ? true
          : cargoParameters.isEmpty;
      const isFull =
        !cargoParameters.isEmpty && !cargoParameters.isFull
          ? true
          : cargoParameters.isFull;
      return {
        // transform assetIdsOptions to IDs
        assetIds:
          cargoParameters.assetIds?.map((asset: any) => asset.id) ?? null,
        assetType: cargoParameters.assetType ?? "",
        isEmpty: isEmpty,
        isFull: isFull,
      };
    }
    case AlertTypeV2.DoorOpenAndMoving: {
      const doorParameters = data?.parameters || {};
      return {
        // transform assetIdsOptions to IDs
        assetIds:
          doorParameters.assetIds?.map((asset: any) => asset.id) ?? null,
        assetType: doorParameters.assetType ?? "",
      };
    }
    case AlertTypeV2.AtisNotFunctioning: {
      const atisNotFunctioningParameters = data?.parameters || {};
      return {
        // transform assetIdsOptions to IDs
        assetIds:
          atisNotFunctioningParameters.assetIds?.map(
            (asset: Record<string, unknown>) => asset.id
          ) ?? null,
        assetType: atisNotFunctioningParameters.assetType ?? "",
      };
    }
    case AlertTypeV2.TirePressureMonitorSystem: {
      const tpmsParameters = data?.parameters || {};
      return {
        tpmsHealthStatus: tpmsParameters.tpmsHealthStatus ?? "",
      };
    }
    case AlertTypeV2.Atis: {
      const atisParameters = data?.parameters || {};
      return {
        atisHealthStatus: atisParameters.atisHealthStatus ?? "",
      };
    }
    case AlertType.Dwell: {
      const dwellParameters = (data?.parameters || {}) as DwellAlertFormValues;
      // If both parameters are unchecked, then insideGeofence and outsideGeofence should be true
      const isInsideGeofence =
        !dwellParameters.insideGeofence && !dwellParameters.outsideGeofence
          ? true
          : dwellParameters.insideGeofence;
      const isOutsideGeofence =
        !dwellParameters.insideGeofence && !dwellParameters.outsideGeofence
          ? true
          : dwellParameters.outsideGeofence;
      return {
        // transform assetIdsOptions to IDs
        assetIds:
          dwellParameters.assetIds?.map((asset: any) => asset.id) ?? null,
        assetType: dwellParameters.assetType ?? "",
        insideGeofence: isInsideGeofence,
        outsideGeofence: isOutsideGeofence,
        dwellThreshold: dwellParameters.dwellThreshold
          ? parse(
              `${dwellParameters.dwellThreshold.toString()}${
                dwellParameters.dwellThresholdUnit
              }`
            )
          : parse("1hours"),
      };
    }
    case AlertTypeV2.SensorHealth: {
      const sensorHealthParameters = data?.parameters || {};
      return {
        assetIds:
          sensorHealthParameters.assetIds?.map(
            (asset: Record<string, unknown>) => asset?.id
          ) ?? null,
        assetType: sensorHealthParameters.assetType ?? "",
        sensorHealthStatus: sensorHealthParameters.sensorHealthStatus ?? "",
      };
    }
    case AlertTypeV2.TirePressureMonitorSystemCatastrophic: {
      const sensorTpmsCatastrophicParameters = data?.parameters || {};
      return {
        assetIds:
          sensorTpmsCatastrophicParameters.assetIds?.map(
            (asset: Record<string, unknown>) => asset?.id
          ) ?? null,
        assetType: sensorTpmsCatastrophicParameters.assetType ?? "",
        pressureThreshold:
          sensorTpmsCatastrophicParameters.pressureThreshold ?? 0,
      };
    }
    case AlertTypeV2.AirTank: {
      const airTankParameters = data?.parameters || {};
      return {
        airTankHealthState: airTankParameters.airTankHealthState ?? "",
      };
    }
    case AlertType.MovingWithoutPrimaryOrSecondaryPower: {
      const movingWithoutPrimOrSecPowerParameters = data?.parameters || {};
      return {
        assetIds:
          movingWithoutPrimOrSecPowerParameters.assetIds?.map(
            (asset: Record<string, unknown>) => asset?.id
          ) ?? null,
        assetType: movingWithoutPrimOrSecPowerParameters.assetType ?? "",
        primary: { lt: 10.5, gt: 14 },
        secondary: { lt: 10.5, gt: 14 },
      };
    }
    case AlertType.Speeding: {
      const speedParameters = data?.parameters as
        | SpeedAlertFormValues
        | undefined;
      const speedValue = speedParameters?.speed ?? "0";

      const speedValueParam =
        speedValueUnit === "Miles" ? +speedValue / 0.62137 : speedValue;

      return {
        speed: String(speedValueParam),
      };
    }

    case AlertTypeV2.WheelEnd: {
      const wheelEndParameters = data?.parameters || {};
      return {
        assetIds:
          wheelEndParameters.assetIds?.map(
            (asset: Record<string, unknown>) => asset?.id
          ) ?? null,
        assetType: wheelEndParameters.assetType ?? "",
        wheelEndHealthState: wheelEndParameters.wheelEndHealthState ?? "",
      };
    }

    case AlertTypeV2.LightCircuit: {
      const lightOutParameters = data?.parameters || {};
      return {
        assetIds:
          lightOutParameters.assetIds?.map(
            (asset: Record<string, unknown>) => asset?.id
          ) ?? null,
        assetType: lightOutParameters.assetType ?? "",
        lightsHealthStatus: lightOutParameters.lightsHealthStatus ?? "",
      };
    }
    case AlertTypeV2.Metrics: {
      const metricsParameters = data?.parameters || {};
      return {
        metric: metricsParameters.metric ?? "",
        operator: metricsParameters.operator ?? "",
        value: metricsParameters.value ?? "",
        startDate: metricsParameters.startDate ?? "",
        endDate: metricsParameters.endDate ?? "",
      };
    }

    case AlertTypeV2.DoorOpenOutsideOfGeofence: {
      const doorOpenOutsideOfGeofenceParameters = data?.parameters || {};
      return {
        assetIds:
          doorOpenOutsideOfGeofenceParameters.assetIds?.map(
            (asset: Record<string, unknown>) => asset?.id
          ) ?? null,
        assetType: doorOpenOutsideOfGeofenceParameters.assetType ?? "",
      };
    }
    case AlertTypeV2.Reefer: {
      const reeferParameters = data?.parameters || {};
      return {
        reeferTemperatureHealthStatus:
          reeferParameters.reeferTemperatureHealthStatus ?? "",
      };
    }
    case AlertTypeV2.AbsFault: {
      const absFaultParameters = data?.parameters || {};
      return {
        assetIds:
          absFaultParameters.assetIds?.map(
            (asset: Record<string, unknown>) => asset?.id
          ) ?? null,
        assetType: absFaultParameters.assetType ?? "",
        absNumberOfFaults: absFaultParameters.absNumberOfFaults ?? "0",
      };
    }
    case AlertTypeV2.Temperature: {
      const temperatureParameters = data?.parameters || {};
      return {
        assetIds:
          temperatureParameters.assetIds?.map(
            (asset: Record<string, unknown>) => asset?.id
          ) ?? null,
        assetType: temperatureParameters.assetType ?? "",
        temperatureHealthStatus:
          temperatureParameters.temperatureHealthStatus ?? "",
      };
    }
    case AlertTypeV2.Regulator: {
      const regulatorParameters = data?.parameters || {};
      return {
        assetIds:
          regulatorParameters.assetIds?.map(
            (asset: Record<string, unknown>) => asset?.id
          ) ?? null,
        assetType: regulatorParameters.assetType ?? "",
        regulatorHealthStatus: regulatorParameters.regulatorHealthStatus ?? "",
      };
    }

    default:
      return {};
  }
};

export const getAlertMultiselectIds = (
  assets?: { id: string | undefined; label: string | null | undefined }[]
) => {
  return (
    assets?.map((asset: any) => {
      if (typeof asset === "string") {
        return asset;
      } else {
        return asset.id;
      }
    }) ?? []
  );
};

interface OnSuccessCreateCallbackProps {
  alertMessage: string;
  dispatch: any;
  created: AlertV2;
  queryClient: QueryClient;
  navigate: (path: string) => void;
  setAlert: (alert: AlertV2) => void;
  setLoading: (loading: boolean) => void;
  setShouldCreateAlert: (shouldCreateAlert: boolean) => void;
}

export const onSuccessCreateCallback = async ({
  alertMessage,
  dispatch,
  created,
  queryClient,
  navigate,
  setAlert,
  setLoading,
  setShouldCreateAlert,
}: OnSuccessCreateCallbackProps) => {
  await queryClient.invalidateQueries(
    [
      "findAlerts",
      "findOrgs",
      "getUserData",
      "getTableViewPreferences",
      "findAssetsByIds",
    ],
    {
      refetchType: "all",
    }
  );

  setShouldCreateAlert(false);
  navigate(`${NavigationRoutes.Alerts}/${slug(created.type)}/${created._id}`);
  setAlert(parseAlertParametersToObject(created));
  dispatch({
    type: PAGE_SNACKBAR,
    payload: {
      title: "Success!",
      text: alertMessage,
      severity: "success",
    },
  });
  setLoading(false);
};

interface OnSuccessCreateTemplateCallbackProps {
  dispatch: any;
  setLoading: (loading: boolean) => void;
  navigate: (path: string) => void;
  queryClient: QueryClient;
}

export const onSuccessCreateTemplateCallback = async ({
  dispatch,
  setLoading,
  navigate,
  queryClient,
}: OnSuccessCreateTemplateCallbackProps) => {
  queryClient.invalidateQueries(["findAlertTemplates"], { refetchType: "all" });
  dispatch({
    type: PAGE_SNACKBAR,
    payload: {
      title: "Success!",
      text: "Alert Template was created successfully!",
      severity: "success",
    },
  });
  setLoading(false);
  navigate(NavigationRoutes.Alerts);
};

interface OnSuccessUpdateCallbackProps {
  alert: AlertV2;
  queryClient: QueryClient;
  dispatch: any;
  setAlert: (alert: AlertV2) => void;
  setLoading: (loading: boolean) => void;
  loadingState: boolean;
}

export const onSuccessUpdateCallback = ({
  alert,
  queryClient,
  dispatch,
  setAlert,
  setLoading,
  loadingState,
}: OnSuccessUpdateCallbackProps) => {
  queryClient.invalidateQueries(["getAlert"], { refetchType: "all" });
  dispatch({
    type: PAGE_SNACKBAR,
    payload: {
      title: "Success!",
      text: "Alert was updated successfully!",
      severity: "success",
    },
  });
  setAlert(parseAlertParametersToObject(alert));
  setLoading(loadingState ?? false);
};

interface OnSuccessDeleteCallbackProps {
  queryClient: QueryClient;
  dispatch: Function;
  setLoading: (loading: boolean) => void;
  navigate: (path: string) => void;
}

export const onSuccessDeleteCallback = async ({
  queryClient,
  dispatch,
  setLoading,
  navigate,
}: OnSuccessDeleteCallbackProps) => {
  await queryClient.invalidateQueries(["findAlerts"], { refetchType: "all" });
  dispatch({
    type: PAGE_SNACKBAR,
    payload: {
      title: "Success!",
      text: "Alert was archived successfully!",
      severity: "success",
    },
  });
  await queryClient.invalidateQueries(["getUserData"], {
    refetchType: "all",
  });
  navigate(NavigationRoutes.Alerts);
  setLoading(false);
};

interface OnErrorCallbackProps {
  error: unknown;
  dispatch: any;
  setLoading: (loading: boolean) => void;
}

export const createAlertOnErrorCallback = ({
  error,
  dispatch,
  setLoading,
}: OnErrorCallbackProps) => {
  dispatch({
    type: PAGE_SNACKBAR,
    payload: {
      title: "Error creating alert",
      text: mapServerErrorCodeToHumanReadableMessage(
        error instanceof Error ? error.message : "Something Went Wrong."
      ),
      severity: "error",
    },
  });
  setLoading(false);
};

interface OnTemplateErrorCallbackProps {
  error: unknown;
  dispatch: any;
  setLoading: (loading: boolean) => void;
}

export const createAlertTemplateOnErrorCallback = ({
  error,
  dispatch,
  setLoading,
}: OnTemplateErrorCallbackProps) => {
  dispatch({
    type: PAGE_SNACKBAR,
    payload: {
      title: "Error creating alert template",
      text: mapServerErrorCodeToHumanReadableMessage(
        error instanceof Error ? error.message : "Something Went Wrong."
      ),
      severity: "error",
    },
  });
  setLoading(false);
};

export const updateAlertOnError = ({
  error,
  dispatch,
  setLoading,
}: OnErrorCallbackProps) => {
  dispatch({
    type: PAGE_SNACKBAR,
    payload: {
      title: "Error updating alert",
      text: mapServerErrorCodeToHumanReadableMessage(
        error instanceof Error ? error.message : "Something Went Wrong."
      ),
      severity: "error",
    },
  });
  setLoading(false);
};

export const deleteAlertOnErrorCallback = ({
  error,
  dispatch,
  setLoading,
}: OnErrorCallbackProps) => {
  dispatch({
    type: PAGE_SNACKBAR,
    payload: {
      title: "Error deleting alert",
      text: mapServerErrorCodeToHumanReadableMessage(
        error instanceof Error ? error.message : "Something Went Wrong."
      ),
      severity: "error",
    },
  });
  setLoading(false);
};

interface OnFindAssetsSettledCallbackProps {
  data: FindAssetsByIdsQuery | undefined;
  form: UseFormReturn<Partial<AlertFormValues>>;
  setSelectedAsset: (asset: any) => void;
}
interface OnFindAssetsSettledOsCallbackProps {
  data: FindAssetsByIdsForSharingOsQuery | undefined;
  form: UseFormReturn<Partial<AlertFormValues>>;
  setSelectedAsset: (asset: any) => void;
}

interface OnFindAssetsByIdsOSCallbackProps {
  data: FindAssetsByIdsOsQuery | undefined;
  form: UseFormReturn<Partial<AlertFormValues>>;
  setSelectedAsset: (asset: any) => void;
}

export const onFindAssetsSettledCallback = ({
  data,
  form,
  setSelectedAsset,
}: OnFindAssetsSettledCallbackProps) => {
  const assets = data?.findAssetsByIds ?? [];
  const assetOptions = assets.map((asset) => ({
    id: asset?._id,
    label: asset?.name,
  }));
  setSelectedAsset(assetOptions ?? null);
};

export const onFindAssetsByIdsOSCallback = ({
  data,
  form,
  setSelectedAsset,
}: OnFindAssetsByIdsOSCallbackProps) => {
  const assets = data?.findAssetsByIdsOS ?? [];
  const assetOptions = assets.map((asset) => ({
    id: asset?._id,
    label: asset?.asset_id,
  }));
  setSelectedAsset(assetOptions ?? null);
};

export const onFindAssetsOsSettledCallback = ({
  data,
  setSelectedAsset,
}: OnFindAssetsSettledOsCallbackProps) => {
  const assets = data?.findAssetsByIdsOS ?? [];
  const assetOptions = assets.map((asset) => ({
    id: asset?._id,
    label: asset?.name,
  }));
  setSelectedAsset(assetOptions ?? null);
};

interface OnFindGeofencesSettledCallbackProps {
  data: FindGeofencesByIdsQuery | undefined;
  setSelectedGeofence: (geofence: any) => void;
}

export const onFindGeofencesSettledCallback = ({
  data,
  setSelectedGeofence,
}: OnFindGeofencesSettledCallbackProps) => {
  const geofences = data?.findGeofencesByIds ?? [];
  const geofenceOptions = geofences.map((geofence) => ({
    id: geofence?._id,
    label: geofence?.geofence?.name,
  }));
  setSelectedGeofence(geofenceOptions ?? null);
};

export const formatMilliseconds = (milliseconds?: number | null) => {
  if (!milliseconds) {
    return {
      value: undefined,
      unit: "",
    };
  }
  const result = getDurationFromMillis(milliseconds)
    .shiftTo("days", "hours")
    .toObject();

  const daysString = result.days ? Number(result.days.toFixed(1)) : 0;
  const hoursString = result.hours ? Number(result.hours.toFixed(1)) : 0;

  if (daysString > 0 && hoursString === 0) {
    // DAYS
    return {
      value: daysString,
      unit: "days",
    };
  } else {
    // HOURS
    return {
      value: daysString * 24 + hoursString,
      unit: "hours",
    };
  }
};

type MatchedAssets = {
  id: string;
  name: string;
  matchedAgainst?: string | null;
};
export const getAssetOptionsList = (matchedAssets: MatchedAssets[]) => {
  const list = matchedAssets.filter(
    (asset) => asset?.matchedAgainst === "assets"
  );
  return (
    list.map((asset) => ({
      id: asset?.id,
      label: asset?.name,
    })) ?? []
  );
};

type MatchedGeofences = {
  id: string;
  name: string;
  matchedAgainst?: string | null;
};
export const getGeofencesOptionsList = (
  matchedGeofences: MatchedGeofences[]
) => {
  const list = matchedGeofences.filter(
    (geofence) => geofence?.matchedAgainst === "geofence"
  );
  return (
    list.map((geofence) => ({
      id: geofence?.id,
      label: geofence?.name,
    })) ?? []
  );
};

export const prepareAlertReminderForSubmit = (data: FieldValues) => {
  const scheduleTimeFrom = data.reminder?.schedule.timeFrom;
  const scheduleTimeFormatFrom = data.reminder?.schedule.timeFormatFrom;
  const scheduleTimeTo = data.reminder?.schedule.timeTo;
  const scheduleTimeFormatTo = data.reminder?.schedule.timeFormatTo;

  const schedule: Partial<AlertSchedule> = {
    days: data.reminder.schedule.days ?? [],
    timeTo:
      scheduleTimeTo && scheduleTimeFormatTo
        ? convertTimeTo24Hour(`${scheduleTimeTo} ${scheduleTimeFormatTo}`)
        : null,
    timeFrom:
      scheduleTimeFrom && scheduleTimeFormatFrom
        ? convertTimeTo24Hour(
            `${data.reminder.schedule.timeFrom} ${data.reminder.schedule.timeFormatFrom}`
          )
        : null,
    endDate: data?.reminder?.schedule.endDate
      ? new Date(data?.reminder?.schedule.endDate).toISOString()
      : null,
    startDate: data?.reminder?.schedule.startDate
      ? new Date(data?.reminder?.schedule.startDate).toISOString()
      : null,
    suspended: data?.reminder?.schedule?.suspended,
  };

  schedule.interval = parse(
    `${data.reminder.schedule.interval} ${data.reminder.schedule.unit}`
  );
  schedule.props = data.reminder.schedule.props;

  const reminder = {
    schedule: schedule,
    subscribers: data.reminder.subscribers,
  };
  return reminder;
};

type IntervalObj = {
  interval: string | null;
  unit: string | null;
};

type Result = {
  days?: number;
  hours?: number;
  minutes?: number;
  seconds?: number;
};

export const getIntervalAndMinutes = (
  milliseconds: number | undefined | null
) => {
  const intervalObj: IntervalObj = {
    interval: null,
    unit: null,
  };
  if (!milliseconds) return intervalObj;

  const result: Result = getDurationFromMillis(milliseconds)
    .shiftTo("days", "hours", "minutes", "seconds")
    .toObject();
  Object.keys(result).forEach((key) => {
    const resultKey = key as keyof Result;
    if (result[resultKey] && result[resultKey] !== 0) {
      intervalObj.interval = `${result[resultKey]}`;
      intervalObj.unit = key;
    }
  });

  return intervalObj;
};

export const getAlertInitialValues = (
  alert: AlertV2,
  speedValueUnit: string,
  currentUserEmail?: string
) => {
  const scheduleTimeFrom = alert?.reminder?.schedule?.timeFrom;
  const scheduleTimeTo = alert?.reminder?.schedule?.timeTo;

  const [timeFrom, timeFormatFrom] = scheduleTimeFrom
    ? convertTimeTo12Hour(scheduleTimeFrom).split(" ")
    : ["", ""];
  const [timeTo, timeFormatTo] = scheduleTimeTo
    ? convertTimeTo12Hour(scheduleTimeTo).split(" ")
    : ["", ""];

  const { unit, interval } = getIntervalAndMinutes(
    alert?.reminder?.schedule?.interval
  );

  type Schedule = {
    days: string[];
    timeFormatTo: string;
    timeTo: string;
    timeFormatFrom: string;
    timeFrom: string;
    endDate: Date | null;
    startDate: Date | null;
    suspended: boolean | null | undefined;
    interval?: string | null;
    unit?: string | null;
    props?: {
      receiveWhenTriggerActive: Maybe<boolean> | undefined;
      sendWhenActive: Maybe<boolean> | undefined;
      sendWhenParked: Maybe<boolean> | undefined;
    };
  };

  const schedule: Schedule = {
    days: alert?.reminder?.schedule?.days ?? [],
    timeFormatTo: timeFormatTo,
    timeTo: timeTo,
    timeFormatFrom: timeFormatFrom,
    timeFrom: timeFrom,
    endDate: alert?.reminder?.schedule?.endDate
      ? parseISODate(alert?.reminder?.schedule.endDate)
      : null,
    startDate: alert?.reminder?.schedule?.startDate
      ? parseISODate(alert?.reminder?.schedule.startDate)
      : null,
    suspended: alert?.reminder?.schedule?.suspended,
  };

  const scheduleProps = {
    receiveWhenTriggerActive:
      alert?.reminder?.schedule?.props?.receiveWhenTriggerActive,
    sendWhenActive: alert?.reminder?.schedule?.props?.sendWhenActive,
    sendWhenParked: alert?.reminder?.schedule?.props?.sendWhenParked,
  };

  schedule.interval = interval;
  schedule.unit = unit;
  schedule.props = scheduleProps;

  if (
    currentUserEmail &&
    alert?.reminder?.subscribers?.emails?.length === 0 &&
    !alert?.reminder?.subscribers?.emails?.includes(currentUserEmail)
  ) {
    alert?.reminder?.subscribers?.emails?.push(currentUserEmail);
  }

  const reminder = {
    schedule: schedule,
    subscribers: alert?.reminder?.subscribers ?? {
      emails: [],
      users: [],
      roles: [],
    },
  };

  const alertProps = {
    name: alert?.name ?? "",
    type: alert?.type,
    parameters: alert?.parameters as Record<string, unknown>,
    reminder: reminder,
    message: alert?.message ?? "",
    trigger: alert?.trigger ?? null,
    escalationTime: alert?.escalationTime ?? { days: 0, hours: 0, minutes: 0 },
    escalationUsers: alert?.escalationUsers ?? [],
    escalationSendSms: alert?.escalationSendSms ?? false,
  };

  if (alert?.type === AlertTypeV2.Dwell) {
    const parameters = alert?.parameters;
    const { value, unit } = formatMilliseconds(parameters?.dwellThreshold);
    alertProps.parameters = {
      assetIds: parameters?.assetIds ?? null,
      assetType: parameters?.assetType ?? null,
      insideGeofence: parameters?.insideGeofence,
      outsideGeofence: parameters?.outsideGeofence,
      dwellThreshold: value,
      dwellThresholdUnit: unit,
    };
  }

  if (alert?.type === AlertTypeV2.Geofence) {
    const parameters = alert?.parameters;
    alertProps.parameters = {
      ...parameters,
      region: parameters?.region ?? [],
      zone: parameters?.zone ?? [],
      geofenceIds: parameters?.geofenceIds ?? [],
      geofenceType: parameters?.geofenceType ?? "",
    };
  }

  if (alert?.type === AlertTypeV2.Cargo) {
    const parameters = alert?.parameters;

    alertProps.parameters = {
      ...parameters,
    };
  }

  if (alert?.type === AlertTypeV2.MovingWithoutPrimaryOrSecondaryPower) {
    const parameters = alert?.parameters;
    alertProps.parameters = parameters;
  }

  if (alert?.type === AlertTypeV2.Speeding) {
    if (!alert?.parameters?.speed) {
      alertProps.parameters = {
        speed: "",
      };
    } else {
      const speedValue =
        speedValueUnit === "Miles"
          ? Math.round((alert?.parameters?.speed || 0) * 0.62137)
          : Math.round(alert?.parameters?.speed);
      alertProps.parameters = {
        speed: speedValue,
      };
    }
  }

  return alertProps;
};

export const commonInitialRemindersValues = [
  {
    schedule: {
      startDate: "",
      endDate: "",
      timeFrom: "",
      timeFormatFrom: "",
      timeTo: "",
      timeFormatTo: "",
      days: [],
    },
    subscribers: { roles: [], users: [], emails: [] },
  },
];

export const isFindAssetByIdQueryEnabled = (
  assets:
    | { id: string | undefined; label: string | null | undefined }[]
    | string[]
    | undefined
) => {
  let shouldBeEnabled = false;
  assets?.forEach((asset) => {
    if (typeof asset === "string") {
      shouldBeEnabled = true;
    }
  });
  return shouldBeEnabled;
};

export const isFindGeofenceByIdQueryEnabled = (
  geofences:
    | { id: string | undefined; label: string | null | undefined }[]
    | string[]
    | undefined
) => {
  let shouldBeEnabled = false;
  geofences?.forEach((geofence) => {
    if (typeof geofence === "string") {
      shouldBeEnabled = true;
    }
  });
  return shouldBeEnabled;
};

export const parseAlertParametersToObject = (alert: AlertV2) => {
  if (alert.parameters) {
    return {
      ...alert,
      parameters: JSON.parse(alert?.parameters),
    };
  }
  return alert;
};
