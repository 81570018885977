import { ChangeEvent, FC, useState } from "react";
import {
  Button as CancelButton,
  Box,
  Grid,
  Input,
  Typography,
  FormControl,
  FormHelperText,
  CircularProgress,
} from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { ReactComponent as ATISSensorLight } from "../../../../../assets/svgs/aitsSensorLight.svg";
import { ReactComponent as ATISSensorDark } from "../../../../../assets/svgs/atisSensorDark.svg";
import { ReactComponent as ImbalanceSensorDark } from "../../../../../assets/svgs/dualImbalanceSensorDark.svg";
import { ReactComponent as ImbalanceSensorLight } from "../../../../../assets/svgs/dualImbalanceSensorLight.svg";
import { ReactComponent as RegulatorSensorDark } from "../../../../../assets/svgs/regulatorSensorDark.svg";
import { ReactComponent as RegulatorSensorLight } from "../../../../../assets/svgs/regulatorSensorLight.svg";
import { ReactComponent as TemperatureSensorDark } from "../../../../../assets/svgs/temperatureSensorDark.svg";
import { ReactComponent as TemperatureSensorLight } from "../../../../../assets/svgs/temperatureSensorLight.svg";
import { ReactComponent as TPMSSensorDark } from "../../../../../assets/svgs/tpmsSensorDark.svg";
import { ReactComponent as TPMSSensorLight } from "../../../../../assets/svgs/tpmsSensorLight.svg";
import { ReactComponent as WheelEndSensorDark } from "../../../../../assets/svgs/wheelEndTemperatureSensorDark.svg";
import { ReactComponent as WheelEndSensorLight } from "../../../../../assets/svgs/wheelEndTemperatureSensorLight.svg";
import { PAGE_SNACKBAR } from "../../../../../constants";
import { useAppContext } from "../../../../../context/AppContext";
import {
  Asset,
  SensorProfileConfigType,
  SetAssetSensorProfilesInput,
  ProfileConfigProperty,
  MergedProfileForAsset,
  SetAssetSensorProfilesMutation,
  useSetAssetSensorProfilesMutation,
  AssetSensorProfilesResponseStatus,
  SetAssetSensorProfilesResponse,
  SensorConfigInputTpmsPressure,
} from "../../../../../graphql/operations";
import { Button } from "../../../../../shared/components/Button";
import { Label } from "../../../../../shared/components/FormControlElement/styledElements";
import SensorSlider from "../../../../../shared/components/SensorSlider/SensorSlider";
import {
  extractRules,
  tpmsTemperatureDefaultValues,
  extractReversedRules,
  regulatorDefaultValues,
  extractDoubleRules,
  tpmsPressureDefaultValues,
  tpmsImbalanceDefaultValues,
  wheenEndTemperatureDefaultValues,
  atisDefaultValues,
  prepareRulesPayload,
  prepareDoubleRulesPayload,
  prepareReversedPayload,
  tpmsPressureMaxHealthy,
  tpmsPressureMinHealthy,
  sliderMarks,
  getMaxValueFromThresholds,
  returnEvenValuesFromRange,
  returnDynamicLimit,
} from "../../../../../shared/components/SensorSlider/sensorSliderUtils";
import Spinner from "../../../../../shared/components/Spinner";
import WithAsterisk from "../../../../../shared/components/WithAsterisk";
import {
  ATIS_ALPHA_HEALTHY_POINT_MAX,
  ATIS_ALPHA_HEALTHY_POINT_MIN,
} from "../../../../../shared/helpers/atis";
import {
  MinValuesBySensorType,
  MaxValuesBySensorType,
} from "../../../../../shared/helpers/battery";
import { SensorValueType } from "../../../../../shared/helpers/sensors.utils";
import { useGetPressureUnitPreference } from "../../../../../shared/hooks/useGetPressureUnitPreference";
import { useUserPermission } from "../../../../../shared/hooks/useUserPermission";
import { mapServerErrorCodeToHumanReadableMessage } from "../../../../../utils";
import {
  getConvertedPressureValues,
  getPressureUnitLabel,
  prepareConvertedMbarPressurePayload,
  prepareConvertedMbarPressuresPayload,
} from "../../../../../utils/convertPressure";
import {
  analyzeResponse,
  handleAnalyzedResponse,
} from "../../../../AdminPanel/tabs/Sensors/sensorsUtils";

interface TiresProps {
  asset: Asset;
  assetMergedSensorProfile: MergedProfileForAsset;
  isAssetRefetching: boolean;
}

export const TiresComponent: FC<TiresProps> = ({
  asset,
  assetMergedSensorProfile,
  isAssetRefetching,
}: TiresProps) => {
  const pressureUnit = useGetPressureUnitPreference();
  const [minTiresPressure, maxTiresPressure] = getConvertedPressureValues(
    [MinValuesBySensorType.tpmsPressure, MaxValuesBySensorType.tpmsPressure],
    pressureUnit
  );
  const [minDualImbalancePressure, maxDualImbalancePressure] =
    getConvertedPressureValues(
      [MinValuesBySensorType.imbalance, MaxValuesBySensorType.imbalance],
      pressureUnit
    );
  const [minSupplyPressure, maxSupplyPressure] = getConvertedPressureValues(
    [
      MinValuesBySensorType.supplyPressure,
      MaxValuesBySensorType.supplyPressure,
    ],
    pressureUnit
  );
  const [tpmsPressureMinHealthyConverted, tpmsPressureMaxHealthyConverted] =
    getConvertedPressureValues(
      [tpmsPressureMinHealthy, tpmsPressureMaxHealthy],
      pressureUnit
    );

  const minAtis = MinValuesBySensorType.atisAlpha;
  const maxAtis = MaxValuesBySensorType.atisAlpha;

  // Extracted Sensor Values
  const temperatureData =
    extractRules(
      assetMergedSensorProfile?.configuration?.tpmsBeta?.temperature?.match
        ?.thresholds
    ) || tpmsTemperatureDefaultValues;
  const movingPressureData = getConvertedPressureValues(
    extractReversedRules(
      assetMergedSensorProfile?.configuration?.psiAirSupply?.supplyPressure
        ?.moving?.match?.thresholds
    ) || regulatorDefaultValues,
    pressureUnit
  );
  const parkedPressureData = getConvertedPressureValues(
    extractReversedRules(
      assetMergedSensorProfile?.configuration?.psiAirSupply?.supplyPressure
        ?.parked?.match?.thresholds
    ) || regulatorDefaultValues,
    pressureUnit
  );
  const pressureData = getConvertedPressureValues(
    extractDoubleRules(
      assetMergedSensorProfile?.configuration?.tpmsBeta?.pressure?.match
        ?.thresholds,
      SensorProfileConfigType.TpmsBeta
    ) || tpmsPressureDefaultValues,
    pressureUnit
  );
  const imbalanceSliderData = getConvertedPressureValues(
    extractRules(
      assetMergedSensorProfile?.configuration?.tpmsBeta?.imbalance?.match
        ?.thresholds
    ) ?? tpmsImbalanceDefaultValues,
    pressureUnit
  );
  const wheelEndTemperatureData =
    extractRules(
      assetMergedSensorProfile?.configuration?.psiWheelEnd?.temperature?.match
        ?.thresholds
    ) ?? wheenEndTemperatureDefaultValues;
  const atisParkedData =
    extractRules(
      assetMergedSensorProfile?.configuration?.atisAlpha?.lightActivatedSeconds
        ?.parked?.match.thresholds
    ) ?? atisDefaultValues;
  const atisMovingData =
    extractRules(
      assetMergedSensorProfile?.configuration?.atisAlpha?.lightActivatedSeconds
        ?.moving?.match?.thresholds
    ) ?? atisDefaultValues;

  const underPressureData = pressureData?.slice(0, 3) || [];
  const overPressureData = pressureData?.slice(4, 7) || [];
  const healthyPressureData = pressureData?.slice(3, 4)[0];
  // Obtain max values straight from thresholds object
  const atisMovingMax =
    getMaxValueFromThresholds(
      assetMergedSensorProfile?.configuration?.atisAlpha?.lightActivatedSeconds
        ?.moving?.match.thresholds
    ) ?? maxAtis;
  const atisParkedMax =
    getMaxValueFromThresholds(
      assetMergedSensorProfile?.configuration?.atisAlpha?.lightActivatedSeconds
        ?.parked?.match.thresholds
    ) ?? maxAtis;

  // State
  const [temperature, setTemperature] = useState(temperatureData);
  const [movingPressure, setMovingPressure] = useState(movingPressureData);
  const [parkedPressure, setParkedPressure] = useState(parkedPressureData);
  const [underPressure, setUnderPressure] = useState(underPressureData);
  const [overPressure, setOverPressure] = useState(overPressureData);
  const [healthyPressure, setHealthyPressure] = useState(healthyPressureData);
  const [dualImbalance, setDualImbalance] = useState(imbalanceSliderData);
  const [wheelEndTemperature, setWheelEndTemperature] = useState(
    wheelEndTemperatureData
  );
  const [atisMoving, setAtisMoving] = useState(atisMovingData); // slider values
  const [atisParked, setAtisParked] = useState(atisParkedData); // slider values
  const [atisParkedMaxValue, setAtisParkedMaxValue] = useState(atisParkedMax);
  const [atisMovingMaxValue, setAtisMovingMaxValue] = useState(atisMovingMax);
  const [healthyPressureError, setHealthyPressureError] = useState<string>("");
  const [atisParkedError, setAtisParkedError] = useState<string>("");
  const [atisMovingError, setAtisMovingError] = useState<string>("");

  // Hooks
  const {
    dispatch,
    state: { theme },
  } = useAppContext();
  const isLightTheme = theme.theme === "light";
  const svgIconSettings = {
    width: "2.5rem",
    height: "2.5rem",
    display: "block",
  };
  const queryClient = useQueryClient();
  const isUserAllowed = useUserPermission("assetManagement.editSettings");

  // GraphQL hooks
  const { mutate: mutateCustomProfile, isLoading } =
    useSetAssetSensorProfilesMutation({
      onSuccess: async (data: SetAssetSensorProfilesMutation) => {
        const response = analyzeResponse(
          data?.setAssetSensorProfiles as SetAssetSensorProfilesResponse[],
          AssetSensorProfilesResponseStatus.Success
        );
        handleAnalyzedResponse(
          response,
          dispatchErrorMessage,
          dispatchSuccessMessage,
          queryClient
        );
      },
    });

  // Handlers
  const handleFormSubmit = async () => {
    if (!asset.imei || healthyPressureError) return;
    const input: SetAssetSensorProfilesInput = {
      selectedImeis: [asset.imei],
      orgId: asset.customer_orgs_id ?? "",
      sensors: {
        psiWheelEnd: {
          temperature: {
            sensorType: SensorValueType.Temperature,
            ...prepareRulesPayload(
              SensorProfileConfigType.PsiWheelEnd,
              wheelEndTemperature,
              MinValuesBySensorType.psiWheelEnd,
              MaxValuesBySensorType.psiWheelEnd
            ),
          },
        },
        atisAlpha: {
          lightActivatedSeconds: {
            moving: {
              sensorType: SensorValueType.Time,
              ...prepareRulesPayload(
                SensorProfileConfigType.AtisAlpha,
                atisMoving,
                minAtis,
                atisMovingMaxValue
              ),
            },
            parked: {
              sensorType: SensorValueType.Time,
              ...prepareRulesPayload(
                SensorProfileConfigType.AtisAlpha,
                atisParked,
                minAtis,
                atisParkedMaxValue
              ),
            },
          },
        },
        tpmsBeta: {
          temperature: {
            sensorType: SensorValueType.Temperature,
            ...prepareRulesPayload(
              ProfileConfigProperty.Temperature,
              temperature,
              MinValuesBySensorType.temperature,
              MaxValuesBySensorType.temperature
            ),
          },
          pressure: {
            sensorType: SensorValueType.Pressure,
            ...(prepareDoubleRulesPayload(
              ProfileConfigProperty.Pressure,
              prepareConvertedMbarPressuresPayload(underPressure, pressureUnit),
              prepareConvertedMbarPressuresPayload(overPressure, pressureUnit),
              prepareConvertedMbarPressurePayload(
                healthyPressure,
                pressureUnit
              ),
              prepareConvertedMbarPressurePayload(
                minTiresPressure,
                pressureUnit
              ),
              prepareConvertedMbarPressurePayload(
                maxTiresPressure,
                pressureUnit
              ),
              "tpmsPressure",
              1
            ) as SensorConfigInputTpmsPressure),
          },
          imbalance: {
            sensorType: SensorValueType.Pressure,
            ...prepareRulesPayload(
              ProfileConfigProperty.Imbalance,
              prepareConvertedMbarPressuresPayload(dualImbalance, pressureUnit),
              prepareConvertedMbarPressurePayload(
                minDualImbalancePressure,
                pressureUnit
              ),
              prepareConvertedMbarPressurePayload(
                maxDualImbalancePressure,
                pressureUnit
              ),
              1
            ),
          },
        },
        psiAirSupply: {
          supplyPressure: {
            moving: {
              sensorType: SensorValueType.Pressure,
              ...prepareReversedPayload(
                ProfileConfigProperty.SupplyPressure,
                prepareConvertedMbarPressuresPayload(
                  movingPressure,
                  pressureUnit
                ),
                prepareConvertedMbarPressurePayload(
                  minSupplyPressure,
                  pressureUnit
                ),
                prepareConvertedMbarPressurePayload(
                  maxSupplyPressure,
                  pressureUnit
                ),
                1
              ),
            },
            parked: {
              sensorType: SensorValueType.Pressure,
              ...prepareReversedPayload(
                ProfileConfigProperty.SupplyPressure,
                prepareConvertedMbarPressuresPayload(
                  parkedPressure,
                  pressureUnit
                ),
                prepareConvertedMbarPressurePayload(
                  minSupplyPressure,
                  pressureUnit
                ),
                prepareConvertedMbarPressurePayload(
                  maxSupplyPressure,
                  pressureUnit
                ),
                1
              ),
            },
          },
        },
      },
    };

    mutateCustomProfile({ input });
  };

  const dispatchSuccessMessage = () => {
    queryClient.invalidateQueries({ queryKey: ["getMergedProfileForAsset"] });
    dispatch({
      type: PAGE_SNACKBAR,
      payload: {
        title: "Success",
        text: "Tires Settings Updated Successfully!",
        severity: "success",
      },
    });
  };

  const dispatchErrorMessage = (message: string) => {
    dispatch({
      type: PAGE_SNACKBAR,
      payload: {
        title: "Settings Uploading Failed",
        text: mapServerErrorCodeToHumanReadableMessage(message),
        severity: "error",
        onClose: () => {},
      },
    });
  };

  const onHealthyValueChange = (e: { target: { value: string } }) => {
    const value = Number(e.target.value);
    if (Number.isNaN(value)) return;

    const pressureErrorMsg = `Number must be between ${tpmsPressureMinHealthyConverted} and ${tpmsPressureMaxHealthyConverted}.`;
    let evenUnder = returnEvenValuesFromRange(minTiresPressure, value);
    let evenOver = returnEvenValuesFromRange(value, maxTiresPressure);
    let errorMsg = "";

    if (value < tpmsPressureMinHealthyConverted) {
      evenUnder = returnEvenValuesFromRange(
        minTiresPressure,
        tpmsPressureMinHealthyConverted
      );
      evenOver = returnEvenValuesFromRange(
        tpmsPressureMinHealthyConverted,
        maxTiresPressure
      );
      errorMsg = pressureErrorMsg;
    } else if (value > tpmsPressureMaxHealthyConverted) {
      evenUnder = returnEvenValuesFromRange(
        minTiresPressure,
        tpmsPressureMaxHealthyConverted
      );
      evenOver = returnEvenValuesFromRange(
        tpmsPressureMaxHealthyConverted,
        maxTiresPressure
      );
      errorMsg = pressureErrorMsg;
    }

    setUnderPressure(evenUnder);
    setOverPressure(evenOver);
    setHealthyPressure(value);
    setHealthyPressureError(errorMsg);
  };

  const atisErrorMsg = `Number must be between ${ATIS_ALPHA_HEALTHY_POINT_MIN} and ${ATIS_ALPHA_HEALTHY_POINT_MAX}.`;

  const onMovingInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value);
    if (Number.isNaN(value)) return true;

    let values = returnEvenValuesFromRange(minAtis, value);
    let movingErrorMsg = "";

    if (value < ATIS_ALPHA_HEALTHY_POINT_MIN) {
      values = returnEvenValuesFromRange(minAtis, ATIS_ALPHA_HEALTHY_POINT_MIN);
      movingErrorMsg = atisErrorMsg;
    } else if (value > ATIS_ALPHA_HEALTHY_POINT_MAX) {
      values = returnEvenValuesFromRange(minAtis, ATIS_ALPHA_HEALTHY_POINT_MAX);
      movingErrorMsg = atisErrorMsg;
    }

    setAtisMoving(values);
    setAtisMovingMaxValue(value);
    setAtisMovingError(movingErrorMsg);
  };

  const onParkedInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value);
    if (Number.isNaN(value)) return true;

    let values = returnEvenValuesFromRange(minAtis, value);
    let parkedErrorMsg = "";

    if (value < ATIS_ALPHA_HEALTHY_POINT_MIN) {
      values = returnEvenValuesFromRange(minAtis, ATIS_ALPHA_HEALTHY_POINT_MIN);
      parkedErrorMsg = atisErrorMsg;
    } else if (value > ATIS_ALPHA_HEALTHY_POINT_MAX) {
      values = returnEvenValuesFromRange(minAtis, ATIS_ALPHA_HEALTHY_POINT_MAX);
      parkedErrorMsg = atisErrorMsg;
    }

    setAtisParked(values);
    setAtisParkedMaxValue(value);
    setAtisParkedError(parkedErrorMsg);
  };

  const handleResetClick = () => {
    setTemperature(temperatureData);
    setUnderPressure(underPressureData);
    setOverPressure(overPressureData);
    setHealthyPressure(healthyPressureData);
    setMovingPressure(movingPressureData);
    setParkedPressure(parkedPressureData);
    setDualImbalance(imbalanceSliderData);
    setWheelEndTemperature(wheelEndTemperatureData);
    setAtisMoving(atisMovingData);
    setAtisParked(atisParkedData);
    setAtisMovingMaxValue(maxAtis);
    setAtisParkedMaxValue(maxAtis);
    setHealthyPressureError("");
    setAtisMovingError("");
    setAtisParkedError("");
  };

  const pressureDynamicLimit = returnDynamicLimit(
    healthyPressure,
    tpmsPressureMinHealthyConverted,
    tpmsPressureMaxHealthyConverted
  );
  const movingMax = returnDynamicLimit(
    atisMovingMaxValue,
    ATIS_ALPHA_HEALTHY_POINT_MIN,
    ATIS_ALPHA_HEALTHY_POINT_MAX
  );
  const parkedMax = returnDynamicLimit(
    atisParkedMaxValue,
    ATIS_ALPHA_HEALTHY_POINT_MIN,
    ATIS_ALPHA_HEALTHY_POINT_MAX
  );

  const temperatureMarks = sliderMarks(
    MinValuesBySensorType.temperature,
    MaxValuesBySensorType.temperature,
    "F"
  );

  const dualimbalanceMarks = sliderMarks(
    minDualImbalancePressure,
    maxDualImbalancePressure,
    getPressureUnitLabel(pressureUnit)
  );

  const wheelEndTemperatureMarks = sliderMarks(
    MinValuesBySensorType.psiWheelEnd,
    MaxValuesBySensorType.psiWheelEnd,
    "F"
  );

  const isAnySliderDirty =
    temperature.toString() !== temperatureData.toString() ||
    overPressure.toString() !== overPressureData.toString() ||
    underPressure.toString() !== underPressureData.toString() ||
    dualImbalance?.toString() !== imbalanceSliderData?.toString() ||
    wheelEndTemperature?.toString() !== wheelEndTemperatureData?.toString() ||
    movingPressure.toString() !== movingPressureData.toString() ||
    parkedPressure.toString() !== parkedPressureData.toString() ||
    atisMoving.toString() !== atisMovingData.toString() ||
    atisParked.toString() !== atisParkedData.toString() ||
    atisParkedMax !== atisParkedMaxValue ||
    atisMovingMax !== atisMovingMaxValue ||
    healthyPressure !== healthyPressureData;

  const areSingleInputsValid = Boolean(
    !atisMovingError && !atisParkedError && !healthyPressureError
  );

  return (
    <>
      <Grid
        container
        className="mb-12 !text-primary"
        data-testid="tires-settings-container"
      >
        <Grid item xs={12}>
          <Box className="pb-8">
            <Box className="mb-16">
              <Typography className="assetSettingsSectionTitle">
                Tire Temperature Settings
              </Typography>
              {isLightTheme ? (
                <TemperatureSensorDark
                  style={svgIconSettings}
                  data-testid="temperature-profile-drawer-thermostat"
                />
              ) : (
                <TemperatureSensorLight
                  style={svgIconSettings}
                  data-testid="temperature-profile-drawer-thermostat"
                />
              )}
            </Box>
            <SensorSlider
              values={temperature}
              min={MinValuesBySensorType.temperature}
              max={MaxValuesBySensorType.temperature}
              marks={temperatureMarks}
              disabled={isLoading || isAssetRefetching}
              onChange={setTemperature}
            />
          </Box>

          <Box className="py-8">
            <Box>
              <Typography
                className="assetSettingsSectionTitle"
                sx={{ marginBottom: "0,75rem" }}
              >
                Tire Pressure Settings
              </Typography>
              {isLightTheme ? (
                <TPMSSensorDark style={svgIconSettings} />
              ) : (
                <TPMSSensorLight style={svgIconSettings} />
              )}
            </Box>

            <Box className="py-4">
              <FormControl sx={{ width: "100%" }}>
                <WithAsterisk>
                  <Label htmlFor="Healthy Value">Healthy Value</Label>
                </WithAsterisk>

                <Input
                  value={healthyPressure}
                  onChange={onHealthyValueChange}
                  data-testid="tires-settings-healthy-pressure-input"
                  id="pressureHealthyVal"
                  disabled={isLoading || isAssetRefetching}
                  sx={{ width: "100%" }}
                />
                {healthyPressureError && (
                  <FormHelperText
                    data-testid="tires-settings-healthy-pressure-error-text"
                    error={true}
                  >
                    {healthyPressureError}
                  </FormHelperText>
                )}
              </FormControl>
            </Box>
          </Box>

          <Box className="py-8">
            <Typography
              className="assetSettingsSectionTitle"
              sx={{ marginBottom: "4rem" }}
            >
              Over Inflation Settings
            </Typography>
            <SensorSlider
              values={overPressure}
              min={pressureDynamicLimit}
              max={maxTiresPressure}
              marks={sliderMarks(
                pressureDynamicLimit,
                maxTiresPressure,
                getPressureUnitLabel(pressureUnit)
              )}
              disabled={isLoading || isAssetRefetching}
              onChange={setOverPressure}
            />
          </Box>

          <Box className="py-8">
            <Typography
              className="assetSettingsSectionTitle"
              sx={{ marginBottom: "4rem" }}
            >
              Under Inflation Settings
            </Typography>
            <SensorSlider
              values={underPressure}
              min={minTiresPressure}
              max={pressureDynamicLimit}
              marks={sliderMarks(
                minTiresPressure,
                pressureDynamicLimit,
                getPressureUnitLabel(pressureUnit)
              )}
              disabled={isLoading || isAssetRefetching}
              onChange={setUnderPressure}
              reversed
            />
          </Box>
          <Box className="py-8">
            <Box className="mb-16">
              <Typography className="assetSettingsSectionTitle">
                Dual Imbalance Settings
              </Typography>
              {isLightTheme ? (
                <ImbalanceSensorDark style={svgIconSettings} />
              ) : (
                <ImbalanceSensorLight style={svgIconSettings} />
              )}
            </Box>
            <SensorSlider
              values={dualImbalance}
              min={minDualImbalancePressure}
              max={maxDualImbalancePressure}
              marks={dualimbalanceMarks}
              disabled={isLoading || isAssetRefetching}
              onChange={setDualImbalance}
            />
          </Box>
          <Box className="py-8">
            <Box className="mb-16">
              <Typography className="assetSettingsSectionTitle">
                Pressure Settings Moving
              </Typography>
              {isLightTheme ? (
                <RegulatorSensorDark style={svgIconSettings} />
              ) : (
                <RegulatorSensorLight style={svgIconSettings} />
              )}
            </Box>
            <SensorSlider
              values={movingPressure}
              min={minSupplyPressure}
              max={maxSupplyPressure}
              marks={sliderMarks(
                minSupplyPressure,
                maxSupplyPressure,
                getPressureUnitLabel(pressureUnit)
              )}
              disabled={isLoading || isAssetRefetching}
              onChange={setMovingPressure}
              reversed
            />
          </Box>

          <Box className="py-8">
            <Box className="mb-16">
              <Typography className="assetSettingsSectionTitle">
                Pressure Settings Parked
              </Typography>
              {isLightTheme ? (
                <RegulatorSensorDark style={svgIconSettings} />
              ) : (
                <RegulatorSensorLight style={svgIconSettings} />
              )}
            </Box>
            <SensorSlider
              values={parkedPressure}
              min={minSupplyPressure}
              max={maxSupplyPressure}
              marks={sliderMarks(
                minSupplyPressure,
                maxSupplyPressure,
                getPressureUnitLabel(pressureUnit)
              )}
              disabled={isLoading || isAssetRefetching}
              onChange={setParkedPressure}
              reversed
            />
          </Box>

          <Box className="py-8">
            <Box className="mb-16">
              <Typography className="assetSettingsSectionTitle">
                Wheel End Temperature Settings
              </Typography>
              {isLightTheme ? (
                <WheelEndSensorDark style={svgIconSettings} />
              ) : (
                <WheelEndSensorLight style={svgIconSettings} />
              )}
            </Box>
            <SensorSlider
              values={wheelEndTemperature}
              min={MinValuesBySensorType.psiWheelEnd}
              max={MaxValuesBySensorType.psiWheelEnd}
              marks={wheelEndTemperatureMarks}
              disabled={isLoading || isAssetRefetching}
              onChange={setWheelEndTemperature}
            />
          </Box>

          <Box className="py-8">
            <Box className="mb-16">
              <Typography className="assetSettingsSectionTitle">
                Atis Settings - Moving
              </Typography>
              {isLightTheme ? (
                <ATISSensorDark
                  style={{ ...svgIconSettings, marginBottom: "0.75rem" }}
                />
              ) : (
                <ATISSensorLight
                  style={{ ...svgIconSettings, marginBottom: "0.75rem" }}
                />
              )}
              <FormControl sx={{ width: "100%" }}>
                <WithAsterisk>
                  <Label htmlFor="Custom end">
                    Set the custom end of the slider
                  </Label>
                </WithAsterisk>

                <Input
                  value={atisMovingMaxValue}
                  onChange={onMovingInputChange}
                  data-testid="tires-settings-atis-moving-value"
                  id="atisMovingMaxValue"
                  disabled={Boolean(isLoading || isAssetRefetching)}
                  sx={{ width: "100%" }}
                />
                {atisMovingError && (
                  <FormHelperText
                    data-testid="tires-settings-atis-moving-error-text"
                    error={true}
                  >
                    {atisMovingError}
                  </FormHelperText>
                )}
              </FormControl>
            </Box>
            <SensorSlider
              values={atisMoving}
              min={minAtis}
              max={movingMax}
              marks={sliderMarks(minAtis, movingMax, "Seconds")}
              disabled={Boolean(isLoading || isAssetRefetching)}
              onChange={setAtisMoving}
            />
          </Box>

          <Box className="py-8">
            <Box className="mb-16">
              <Typography className="assetSettingsSectionTitle">
                Atis Settings - Parked
              </Typography>
              {isLightTheme ? (
                <ATISSensorDark
                  style={{ ...svgIconSettings, marginBottom: "0.75rem" }}
                />
              ) : (
                <ATISSensorLight
                  style={{ ...svgIconSettings, marginBottom: "0.75rem" }}
                />
              )}
              <FormControl sx={{ width: "100%" }}>
                <WithAsterisk>
                  <Label htmlFor="Healthy Value">
                    Set the custom end of the slider
                  </Label>
                </WithAsterisk>

                <Input
                  value={atisParkedMaxValue}
                  onChange={onParkedInputChange}
                  data-testid="tires-settings-atis-parked-value"
                  id="atisParkedMaxValue"
                  disabled={isLoading || isAssetRefetching}
                  sx={{ width: "100%" }}
                />
                {atisParkedError && (
                  <FormHelperText
                    data-testid="tires-settings-atis-parked-error-text"
                    error={true}
                  >
                    {atisParkedError}
                  </FormHelperText>
                )}
              </FormControl>
            </Box>
            <SensorSlider
              values={atisParked}
              min={minAtis}
              max={parkedMax}
              marks={sliderMarks(minAtis, parkedMax, "Seconds")}
              disabled={isLoading || isAssetRefetching}
              onChange={setAtisParked}
            />
          </Box>
        </Grid>
      </Grid>
      {/* TODO: Replace with reusable component after it's fixed to accept 2 disabled states */}
      {isUserAllowed && (
        <Grid
          container
          spacing={8}
          className="items-baseline justify-center md:justify-end"
        >
          <Grid item>
            <CancelButton
              data-testid="settings-cancel"
              className="global-text-btn global-text-btn--medium global-text-btn__theme--blue !font-bold !capitalize !text-brand"
              onClick={handleResetClick}
              disabled={isLoading || !isAnySliderDirty}
            >
              Reset
            </CancelButton>
          </Grid>
          <Grid item>
            <Button
              dataTestid="settings-submit"
              className="w-full"
              text={isLoading ? "Saving" : "Save"}
              size="medium"
              theme="blue"
              variant="default"
              type="button"
              onClick={handleFormSubmit}
              iconPosition="right"
              icon={
                isLoading && (
                  <CircularProgress size={15} style={{ color: "white" }} />
                )
              }
              disabled={Boolean(
                isLoading || !isAnySliderDirty || !areSingleInputsValid
              )}
            />
          </Grid>
        </Grid>
      )}
      <Spinner counter={Number(isLoading || isAssetRefetching)} />
    </>
  );
};
