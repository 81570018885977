import { FC } from "react";
import { UseFormReturn } from "react-hook-form";
import { AutocompleteElement } from "react-hook-form-mui";
import { Grid } from "@mui/material";
import { SensorStatus } from "../../../../graphql/operations";
import { AlertFormValues } from "../../interfaces";
import { AlertParametersContainer } from "../AlertParametersContainer";

interface AlertAirTankParametersProps {
  form: UseFormReturn<Partial<AlertFormValues>>;
}
export const AlertAirTankParameters: FC<AlertAirTankParametersProps> = ({
  form,
}: AlertAirTankParametersProps) => {
  const healthStateOptions = [
    { id: SensorStatus.Warning, label: "Warning" },
    { id: SensorStatus.Alert, label: "Alert" },
    { id: SensorStatus.Critical, label: "Critical" },
  ];

  return (
    <AlertParametersContainer>
      <Grid
        item
        xs={12}
        md={6}
        lg={6}
        xl={4}
        data-testid="air-tank-asset-health-state"
      >
        <AutocompleteElement
          matchId={true}
          label="Air Tank Health status"
          control={form.control}
          name="parameters.airTankHealthState"
          options={healthStateOptions}
        />
      </Grid>
    </AlertParametersContainer>
  );
};
