import { GridValueGetterParams } from "@mui/x-data-grid-premium";
import { get } from "lodash";
import { Maybe } from "yup/lib/types";
import {
  SensorsReportDataRow,
  TableColumnFormat as ReportColumnFormat,
  TableValueDataType,
} from "../../../../../graphql/operations";
import { imeiValueFormatter } from "../../../../../utils";
import { ReportGridColDef } from "../../../helpers/helpers";

export const defaultColumnVisibilityModel = {
  assetId: false,
  assetName: false,
  imei: false,
  "voltage.primaryVoltage": false,
  "voltage.secondaryVoltage": false,
  "voltage.solarBatteryVoltage": false,
  "voltage.batteryVoltage": false,
  "voltage.batteryHealthStatus": false,
  "voltage.voltageHealthStatus": false,
  "bleDoor.doorType": false,
  "bleDoor.doorSensorStatus": false,
  "abs.faultCount": false,
  "odometer.odometerKmph": false,
  "atis.healthStatus": false,
  "atis.lightActivatedNumber": false,
  "atis.lightActivatedSeconds": false,
  "temperature.ambient": false,
  "temperature.internal": false,
  "temperature.healthStatus": false,
  "tpms.tpmsStatus": false,
  "tpms.tirePressureLO[0]": false,
  "tpms.tirePressureLI[0]": false,
  "tpms.tirePressureRI[0]": false,
  "tpms.tirePressureRO[0]": false,
  "tpms.tireTemperatureLO[0]": false,
  "tpms.tireTemperatureLI[0]": false,
  "tpms.tireTemperatureRI[0]": false,
  "tpms.tireTemperatureRO[0]": false,
  "tpms.axleHealthStatus[0]": false,
  "tpms.tireLOHealthStatus[0]": false,
  "tpms.tireLIHealthStatus[0]": false,
  "tpms.tireROHealthStatus[0]": false,
  "tpms.tireRIHealthStatus[0]": false,
  "tpms.tirePressureLOHealthStatus[0]": false,
  "tpms.tirePressureLIHealthStatus[0]": false,
  "tpms.tirePressureROHealthStatus[0]": false,
  "tpms.tirePressureRIHealthStatus[0]": false,
  "tpms.tireTemperatureLOHealthStatus[0]": false,
  "tpms.tireTemperatureLIHealthStatus[0]": false,
  "tpms.tireTemperatureROHealthStatus[0]": false,
  "tpms.tireTemperatureRIHealthStatus[0]": false,
  "tpms.rightImbalance[0]": false,
  "tpms.leftImbalance[0]": false,
  "tpms.tirePressureLO[1]": false,
  "tpms.tirePressureLI[1]": false,
  "tpms.tirePressureRI[1]": false,
  "tpms.tirePressureRO[1]": false,
  "tpms.tireTemperatureLO[1]": false,
  "tpms.tireTemperatureLI[1]": false,
  "tpms.tireTemperatureRI[1]": false,
  "tpms.tireTemperatureRO[1]": false,
  "tpms.axleHealthStatus[1]": false,
  "tpms.tireLOHealthStatus[1]": false,
  "tpms.tireLIHealthStatus[1]": false,
  "tpms.tireROHealthStatus[1]": false,
  "tpms.tireRIHealthStatus[1]": false,
  "tpms.tirePressureLOHealthStatus[1]": false,
  "tpms.tirePressureLIHealthStatus[1]": false,
  "tpms.tirePressureROHealthStatus[1]": false,
  "tpms.tirePressureRIHealthStatus[1]": false,
  "tpms.tireTemperatureLOHealthStatus[1]": false,
  "tpms.tireTemperatureLIHealthStatus[1]": false,
  "tpms.tireTemperatureROHealthStatus[1]": false,
  "tpms.tireTemperatureRIHealthStatus[1]": false,
  "tpms.rightImbalance[1]": false,
  "tpms.leftImbalance[1]": false,
  "tpms.tirePressureLO[2]": false,
  "tpms.tirePressureLI[2]": false,
  "tpms.tirePressureRI[2]": false,
  "tpms.tirePressureRO[2]": false,
  "tpms.tireTemperatureLO[2]": false,
  "tpms.tireTemperatureLI[2]": false,
  "tpms.tireTemperatureRI[2]": false,
  "tpms.tireTemperatureRO[2]": false,
  "tpms.axleHealthStatus[2]": false,
  "tpms.tireLOHealthStatus[2]": false,
  "tpms.tireLIHealthStatus[2]": false,
  "tpms.tireROHealthStatus[2]": false,
  "tpms.tireRIHealthStatus[2]": false,
  "tpms.tirePressureLOHealthStatus[2]": false,
  "tpms.tirePressureLIHealthStatus[2]": false,
  "tpms.tirePressureROHealthStatus[2]": false,
  "tpms.tirePressureRIHealthStatus[2]": false,
  "tpms.tireTemperatureLOHealthStatus[2]": false,
  "tpms.tireTemperatureLIHealthStatus[2]": false,
  "tpms.tireTemperatureROHealthStatus[2]": false,
  "tpms.tireTemperatureRIHealthStatus[2]": false,
  "tpms.rightImbalance[2]": false,
  "tpms.leftImbalance[2]": false,
  "tpms.overallTPMSHealthStatus": false,
  "tpms.overallTPMSTemperatureHealthStatus": false,
  "tpms.overallTPMSPressureHealthStatus": false,
  "tpms.overallTPMSImbalanceHealthStatus": false,
  "psiWheelEnd.leftWheelEndTemperature": false,
  "psiWheelEnd.rightWheelEndTemperature": false,
  "psiWheelEnd.leftWheelEndStatus": false,
  "psiWheelEnd.rightWheelEndStatus": false,
  "psiWheelEnd.wheelEndStatus": false,
  "lights.lightsStatus": false,
  "lights.brakesLightOut": false,
  "lights.rightTurnSignalLightOut": false,
  "lights.leftTurnSignalLightOut": false,
  "lights.markerLightOut": false,
  "lights.licenseLightOut": false,
  "psiAirSupply.airSupplyHealthStatus": false,
  "psiAirSupply.airTankPressure": false,
  "psiAirSupply.airTankHealthStatus": false,
  "psiAirSupply.regulator": false,
  "psiAirSupply.regulatorHealthStatus": false,
  "airBag.healthStatus": false,
  "airBag.leftAirBagPressure": false,
  "airBag.rightAirBagPressure": false,
  "cargo.cargoState": false,
  "internalCamera.healthStatus": false,
  "internalCamera.floorUsage": false,
  "liftgate.healthStatus": false,
  "liftgate.voltage": false,
  healthSensors: false,
  "ultrasonic.distanceMeasurement": false,
  "ultrasonic.statusUltrasonic": false,
  "ultrasonic.readingDate": false,
  overallLightOutHealthStatus: false,
  unhealthySensors: false,
};

export const SENSORS_COLUMN_TYPES_MAP: Record<string, TableValueDataType> = {
  assetId: TableValueDataType.String,
  assetName: TableValueDataType.String,
  imei: TableValueDataType.String,
  "voltage.primaryVoltage": TableValueDataType.Number,
  "voltage.secondaryVoltage": TableValueDataType.Number,
  "voltage.solarBatteryVoltage": TableValueDataType.Number,
  "voltage.batteryVoltage": TableValueDataType.Number,
  "voltage.batteryHealthStatus": TableValueDataType.String,
  "voltage.voltageHealthStatus": TableValueDataType.String,
  "bleDoor.doorType": TableValueDataType.String,
  "bleDoor.doorSensorStatus": TableValueDataType.String,
  "abs.faultCount": TableValueDataType.Number,
  "odometer.odometerKmph": TableValueDataType.Number,
  "atis.healthStatus": TableValueDataType.String,
  "atis.lightActivatedNumber": TableValueDataType.Number,
  "atis.lightActivatedSeconds": TableValueDataType.Number,
  "temperature.ambient": TableValueDataType.Number,
  "temperature.internal": TableValueDataType.Number,
  "temperature.healthStatus": TableValueDataType.String,
  "tpms.tpmsStatus": TableValueDataType.String,
  "tpms.tirePressureLO[0]": TableValueDataType.Number,
  "tpms.tirePressureLI[0]": TableValueDataType.Number,
  "tpms.tirePressureRI[0]": TableValueDataType.Number,
  "tpms.tirePressureRO[0]": TableValueDataType.Number,
  "tpms.tireTemperatureLO[0]": TableValueDataType.Number,
  "tpms.tireTemperatureLI[0]": TableValueDataType.Number,
  "tpms.tireTemperatureRI[0]": TableValueDataType.Number,
  "tpms.tireTemperatureRO[0]": TableValueDataType.Number,
  "tpms.axleHealthStatus[0]": TableValueDataType.Number,
  "tpms.tireLOHealthStatus[0]": TableValueDataType.String,
  "tpms.tireLIHealthStatus[0]": TableValueDataType.String,
  "tpms.tireROHealthStatus[0]": TableValueDataType.String,
  "tpms.tireRIHealthStatus[0]": TableValueDataType.String,
  "tpms.tirePressureLOHealthStatus[0]": TableValueDataType.String,
  "tpms.tirePressureLIHealthStatus[0]": TableValueDataType.String,
  "tpms.tirePressureROHealthStatus[0]": TableValueDataType.String,
  "tpms.tirePressureRIHealthStatus[0]": TableValueDataType.String,
  "tpms.tireTemperatureLOHealthStatus[0]": TableValueDataType.String,
  "tpms.tireTemperatureLIHealthStatus[0]": TableValueDataType.String,
  "tpms.tireTemperatureROHealthStatus[0]": TableValueDataType.String,
  "tpms.tireTemperatureRIHealthStatus[0]": TableValueDataType.String,
  "tpms.rightImbalance[0]": TableValueDataType.Number,
  "tpms.leftImbalance[0]": TableValueDataType.Number,
  "tpms.tirePressureLO[1]": TableValueDataType.Number,
  "tpms.tirePressureLI[1]": TableValueDataType.Number,
  "tpms.tirePressureRI[1]": TableValueDataType.Number,
  "tpms.tirePressureRO[1]": TableValueDataType.Number,
  "tpms.tireTemperatureLO[1]": TableValueDataType.Number,
  "tpms.tireTemperatureLI[1]": TableValueDataType.Number,
  "tpms.tireTemperatureRI[1]": TableValueDataType.Number,
  "tpms.tireTemperatureRO[1]": TableValueDataType.Number,
  "tpms.axleHealthStatus[1]": TableValueDataType.String,
  "tpms.tireLOHealthStatus[1]": TableValueDataType.String,
  "tpms.tireLIHealthStatus[1]": TableValueDataType.String,
  "tpms.tireROHealthStatus[1]": TableValueDataType.String,
  "tpms.tireRIHealthStatus[1]": TableValueDataType.String,
  "tpms.tirePressureLOHealthStatus[1]": TableValueDataType.String,
  "tpms.tirePressureLIHealthStatus[1]": TableValueDataType.String,
  "tpms.tirePressureROHealthStatus[1]": TableValueDataType.String,
  "tpms.tirePressureRIHealthStatus[1]": TableValueDataType.String,
  "tpms.tireTemperatureLOHealthStatus[1]": TableValueDataType.String,
  "tpms.tireTemperatureLIHealthStatus[1]": TableValueDataType.String,
  "tpms.tireTemperatureROHealthStatus[1]": TableValueDataType.String,
  "tpms.tireTemperatureRIHealthStatus[1]": TableValueDataType.String,
  "tpms.rightImbalance[1]": TableValueDataType.Number,
  "tpms.leftImbalance[1]": TableValueDataType.Number,
  "tpms.tirePressureLO[2]": TableValueDataType.Number,
  "tpms.tirePressureLI[2]": TableValueDataType.Number,
  "tpms.tirePressureRI[2]": TableValueDataType.Number,
  "tpms.tirePressureRO[2]": TableValueDataType.Number,
  "tpms.tireTemperatureLO[2]": TableValueDataType.Number,
  "tpms.tireTemperatureLI[2]": TableValueDataType.Number,
  "tpms.tireTemperatureRI[2]": TableValueDataType.Number,
  "tpms.tireTemperatureRO[2]": TableValueDataType.Number,
  "tpms.axleHealthStatus[2]": TableValueDataType.String,
  "tpms.tireLOHealthStatus[2]": TableValueDataType.String,
  "tpms.tireLIHealthStatus[2]": TableValueDataType.String,
  "tpms.tireROHealthStatus[2]": TableValueDataType.String,
  "tpms.tireRIHealthStatus[2]": TableValueDataType.String,
  "tpms.tirePressureLOHealthStatus[2]": TableValueDataType.String,
  "tpms.tirePressureLIHealthStatus[2]": TableValueDataType.String,
  "tpms.tirePressureROHealthStatus[2]": TableValueDataType.String,
  "tpms.tirePressureRIHealthStatus[2]": TableValueDataType.String,
  "tpms.tireTemperatureLOHealthStatus[2]": TableValueDataType.String,
  "tpms.tireTemperatureLIHealthStatus[2]": TableValueDataType.String,
  "tpms.tireTemperatureROHealthStatus[2]": TableValueDataType.String,
  "tpms.tireTemperatureRIHealthStatus[2]": TableValueDataType.String,
  "tpms.rightImbalance[2]": TableValueDataType.Number,
  "tpms.leftImbalance[2]": TableValueDataType.Number,
  "tpms.overallTPMSHealthStatus": TableValueDataType.String,
  "tpms.overallTPMSTemperatureHealthStatus": TableValueDataType.String,
  "tpms.overallTPMSPressureHealthStatus": TableValueDataType.String,
  "tpms.overallTPMSImbalanceHealthStatus": TableValueDataType.String,
  "psiWheelEnd.leftWheelEndTemperature": TableValueDataType.Number,
  "psiWheelEnd.rightWheelEndTemperature": TableValueDataType.Number,
  "psiWheelEnd.leftWheelEndStatus": TableValueDataType.String,
  "psiWheelEnd.rightWheelEndStatus": TableValueDataType.String,
  "psiWheelEnd.wheelEndStatus": TableValueDataType.String,
  "lights.lightsStatus": TableValueDataType.String,
  "lights.brakesLightOut": TableValueDataType.String,
  "lights.rightTurnSignalLightOut": TableValueDataType.String,
  "lights.leftTurnSignalLightOut": TableValueDataType.String,
  "lights.markerLightOut": TableValueDataType.String,
  "lights.licenseLightOut": TableValueDataType.String,
  "psiAirSupply.airSupplyHealthStatus": TableValueDataType.String,
  "psiAirSupply.airTankPressure": TableValueDataType.Number,
  "psiAirSupply.airTankHealthStatus": TableValueDataType.String,
  "psiAirSupply.regulator": TableValueDataType.Number,
  "psiAirSupply.regulatorHealthStatus": TableValueDataType.String,
  "airBag.healthStatus": TableValueDataType.String,
  "airBag.leftAirBagPressure": TableValueDataType.Number,
  "airBag.rightAirBagPressure": TableValueDataType.Number,
  "cargo.cargoState": TableValueDataType.String,
  "internalCamera.healthStatus": TableValueDataType.String,
  "internalCamera.floorUsage": TableValueDataType.Number,
  "liftgate.healthStatus": TableValueDataType.String,
  "liftgate.voltage": TableValueDataType.Number,
  "ultrasonic.readingDate": TableValueDataType.Date,
  "ultrasonic.statusUltrasonic": TableValueDataType.String,
  "ultrasonic.distanceMeasurement": TableValueDataType.Number,
};
export const getColumns = (
  timezone: Maybe<string> | undefined
): ReportGridColDef[] => {
  const commonColumnConfig = {
    flex: 1,
    minWidth: 120,
  };

  const createColumn = (
    field: string,
    headerName: string,
    format: ReportColumnFormat,
    additionalConfig = {}
  ) => ({
    field,
    headerName,
    format,
    ...commonColumnConfig,
    ...additionalConfig,
  });

  const getColumnValue = (
    params: GridValueGetterParams<any, SensorsReportDataRow>,
    path: string | number
  ) => {
    return get(params.row, path);
  };

  const assetColumns = [
    createColumn("assetId", "Asset ID", ReportColumnFormat.String),
    createColumn("assetName", "Asset Name", ReportColumnFormat.String),
    createColumn("imei", "IMEI", ReportColumnFormat.ImeiString, {
      valueFormatter: imeiValueFormatter,
    }),
  ];

  const sensorFields = [
    {
      key: "voltage.primaryVoltage",
      label: "Primary Battery Voltage",
      type: "number",
    },
    {
      key: "voltage.secondaryVoltage",
      label: "Secondary Battery Voltage",
      type: "number",
    },
    {
      key: "voltage.solarBatteryVoltage",
      label: "Solar Battery Voltage",
      type: "number",
    },
    { key: "voltage.batteryVoltage", label: "Battery Voltage", type: "number" },
    { key: "voltage.batteryHealthStatus", label: "Battery Health Status" },
    { key: "voltage.voltageHealthStatus", label: "Voltage Health Status" },
    { key: "bleDoor.doorType", label: "Door Type" },
    { key: "bleDoor.doorSensorStatus", label: "Door State" },
    { key: "abs.faultCount", label: "ABS Fault Code Count", type: "number" },
    { key: "odometer.odometerKmph", label: "Odometer (kmph)", type: "number" },
    { key: "atis.healthStatus", label: "Atis Health Status" },
    {
      key: "atis.lightActivatedNumber",
      label: "Atis Light Activated Count",
      type: "number",
    },
    {
      key: "atis.lightActivatedSeconds",
      label: "Atis Light Activated Time",
      type: "number",
    },
    {
      key: "temperature.ambient",
      label: "Temperature External",
      type: "number",
    },
    {
      key: "temperature.internal",
      label: "Temperature Internal",
      type: "number",
    },
    { key: "temperature.healthStatus", label: "Temperature Health Status" },
    { key: "tpms.tpmsStatus", label: "TMPS Status" },
    {
      key: "tpms.tirePressureLO[0]",
      label: "Tire Pressure LO Axle One",
      type: "number",
      filterable: false,
    },
    {
      key: "tpms.tirePressureLI[0]",
      label: "Tire Pressure LI Axle One",
      type: "number",
      filterable: false,
    },
    {
      key: "tpms.tirePressureRI[0]",
      label: "Tire Pressure RI Axle One",
      type: "number",
      filterable: false,
    },
    {
      key: "tpms.tirePressureRO[0]",
      label: "Tire Pressure RO Axle One",
      type: "number",
      filterable: false,
    },
    {
      key: "tpms.tireTemperatureLO[0]",
      label: "Tire Temperature LO Axle One",
      type: "number",
      filterable: false,
    },
    {
      key: "tpms.tireTemperatureLI[0]",
      label: "Tire Temperature LI Axle One",
      type: "number",
      filterable: false,
    },
    {
      key: "tpms.tireTemperatureRI[0]",
      label: "Tire Temperature RI Axle One",
      type: "number",
      filterable: false,
    },
    {
      key: "tpms.tireTemperatureRO[0]",
      label: "Tire Temperature RO Axle One",
      type: "number",
      filterable: false,
    },
    {
      key: "tpms.tireLOHealthStatus[0]",
      label: "Tire LO Health Status Axle One",
      filterable: false,
    },
    {
      key: "tpms.tireLIHealthStatus[0]",
      label: "Tire LI Health Status Axle One",
      filterable: false,
    },
    {
      key: "tpms.tireROHealthStatus[0]",
      label: "Tire RO Health Status Axle One",
      filterable: false,
    },
    {
      key: "tpms.tireRIHealthStatus[0]",
      label: "Tire RI Health Status Axle One",
      filterable: false,
    },
    {
      key: "tpms.tirePressureLOHealthStatus[0]",
      label: "Tire LO Pressure Health Status Axle One",
      filterable: false,
    },
    {
      key: "tpms.tirePressureLIHealthStatus[0]",
      label: "Tire LI Pressure Health Status Axle One",
      filterable: false,
    },
    {
      key: "tpms.tirePressureROHealthStatus[0]",
      label: "Tire RO Pressure Health Status Axle One",
      filterable: false,
    },
    {
      key: "tpms.tirePressureRIHealthStatus[0]",
      label: "Tire RI Pressure Health Status Axle One",
      filterable: false,
    },
    {
      key: "tpms.tireTemperatureLOHealthStatus[0]",
      label: "Tire LO Temperature Health Status Axle One",
      filterable: false,
    },
    {
      key: "tpms.tireTemperatureLIHealthStatus[0]",
      label: "Tire LI Temperature Health Status Axle One",
      filterable: false,
    },
    {
      key: "tpms.tireTemperatureROHealthStatus[0]",
      label: "Tire RO Temperature Health Status Axle One",
      filterable: false,
    },
    {
      key: "tpms.tireTemperatureRIHealthStatus[0]",
      label: "Tire RI Temperature Health Status Axle One",
      filterable: false,
    },
    {
      key: "tpms.rightImbalance[0]",
      label: "Axle One Right Imbalance",
      type: "number",
      filterable: false,
    },
    {
      key: "tpms.leftImbalance[0]",
      label: "Axle One Left Imbalance",
      type: "number",
      filterable: false,
    },
    {
      key: "tpms.tirePressureLO[1]",
      label: "Tire Pressure LO Axle Two",
      type: "number",
      filterable: false,
    },
    {
      key: "tpms.tirePressureLI[1]",
      label: "Tire Pressure LI Axle Two",
      type: "number",
      filterable: false,
    },
    {
      key: "tpms.tirePressureRI[1]",
      label: "Tire Pressure RI Axle Two",
      type: "number",
      filterable: false,
    },
    {
      key: "tpms.tirePressureRO[1]",
      label: "Tire Pressure RO Axle Two",
      type: "number",
      filterable: false,
    },
    {
      key: "tpms.tireTemperatureLO[1]",
      label: "Tire Temperature LO Axle Two",
      type: "number",
      filterable: false,
    },
    {
      key: "tpms.tireTemperatureLI[1]",
      label: "Tire Temperature LI Axle Two",
      type: "number",
      filterable: false,
    },
    {
      key: "tpms.tireTemperatureRI[1]",
      label: "Tire Temperature RI Axle Two",
      type: "number",
      filterable: false,
    },
    {
      key: "tpms.tireTemperatureRO[1]",
      label: "Tire Temperature RO Axle Two",
      type: "number",
      filterable: false,
    },
    {
      key: "tpms.tireLOHealthStatus[1]",
      label: "Tire LO Health Status Axle Two",
      filterable: false,
    },
    {
      key: "tpms.tireLIHealthStatus[1]",
      label: "Tire LI Health Status Axle Two",
      filterable: false,
    },
    {
      key: "tpms.tireROHealthStatus[1]",
      label: "Tire RO Health Status Axle Two",
      filterable: false,
    },
    {
      key: "tpms.tireRIHealthStatus[1]",
      label: "Tire RI Health Status Axle Two",
      filterable: false,
    },
    {
      key: "tpms.tirePressureLOHealthStatus[1]",
      label: "Tire LO Pressure Health Status Axle Two",
      filterable: false,
    },
    {
      key: "tpms.tirePressureLIHealthStatus[1]",
      label: "Tire LI Pressure Health Status Axle Two",
      filterable: false,
    },
    {
      key: "tpms.tirePressureROHealthStatus[1]",
      label: "Tire RO Pressure Health Status Axle Two",
      filterable: false,
    },
    {
      key: "tpms.tirePressureRIHealthStatus[1]",
      label: "Tire RI Pressure Health Status Axle Two",
      filterable: false,
    },
    {
      key: "tpms.tireTemperatureLOHealthStatus[1]",
      label: "Tire LO Temperature Health Status Axle Two",
      filterable: false,
    },
    {
      key: "tpms.tireTemperatureLIHealthStatus[1]",
      label: "Tire LI Temperature Health Status Axle Two",
      filterable: false,
    },
    {
      key: "tpms.tireTemperatureROHealthStatus[1]",
      label: "Tire RO Temperature Health Status Axle Two",
      filterable: false,
    },
    {
      key: "tpms.tireTemperatureRIHealthStatus[1]",
      label: "Tire RI Temperature Health Status Axle Two",
      filterable: false,
    },
    {
      key: "tpms.rightImbalance[1]",
      label: "Axle Two Right Imbalance",
      type: "number",
      filterable: false,
    },
    {
      key: "tpms.leftImbalance[1]",
      label: "Axle Two Left Imbalance",
      type: "number",
      filterable: false,
    },
    {
      key: "tpms.tirePressureLO[2]",
      label: "Tire Pressure LO Axle Three",
      type: "number",
      filterable: false,
    },
    {
      key: "tpms.tirePressureLI[2]",
      label: "Tire Pressure LI Axle Three",
      type: "number",
      filterable: false,
    },
    {
      key: "tpms.tirePressureRI[2]",
      label: "Tire Pressure RI Axle Three",
      type: "number",
      filterable: false,
    },
    {
      key: "tpms.tirePressureRO[2]",
      label: "Tire Pressure RO Axle Three",
      type: "number",
      filterable: false,
    },
    {
      key: "tpms.tireTemperatureLO[2]",
      label: "Tire Temperature LO Axle Three",
      type: "number",
      filterable: false,
    },
    {
      key: "tpms.tireTemperatureLI[2]",
      label: "Tire Temperature LI Axle Three",
      type: "number",
      filterable: false,
    },
    {
      key: "tpms.tireTemperatureRI[2]",
      label: "Tire Temperature RI Axle Three",
      type: "number",
      filterable: false,
    },
    {
      key: "tpms.tireTemperatureRO[2]",
      label: "Tire Temperature RO Axle Three",
      type: "number",
      filterable: false,
    },
    {
      key: "tpms.tireLOHealthStatus[2]",
      label: "Tire LO Health Status Axle Three",
      filterable: false,
    },
    {
      key: "tpms.tireLIHealthStatus[2]",
      label: "Tire LI Health Status Axle Three",
      filterable: false,
    },
    {
      key: "tpms.tireROHealthStatus[2]",
      label: "Tire RO Health Status Axle Three",
      filterable: false,
    },
    {
      key: "tpms.tireRIHealthStatus[2]",
      label: "Tire RI Health Status Axle Three",
      filterable: false,
    },
    {
      key: "tpms.tirePressureLOHealthStatus[2]",
      label: "Tire LO Pressure Health Status Axle Three",
      filterable: false,
    },
    {
      key: "tpms.tirePressureLIHealthStatus[2]",
      label: "Tire LI Pressure Health Status Axle Three",
      filterable: false,
    },
    {
      key: "tpms.tirePressureROHealthStatus[2]",
      label: "Tire RO Pressure Health Status Axle Three",
      filterable: false,
    },
    {
      key: "tpms.tirePressureRIHealthStatus[2]",
      label: "Tire RI Pressure Health Status Axle Three",
      filterable: false,
    },
    {
      key: "tpms.tireTemperatureLOHealthStatus[2]",
      label: "Tire LO Temperature Health Status Axle Three",
      filterable: false,
    },
    {
      key: "tpms.tireTemperatureLIHealthStatus[2]",
      label: "Tire LI Temperature Health Status Axle Three",
      filterable: false,
    },
    {
      key: "tpms.tireTemperatureROHealthStatus[2]",
      label: "Tire RO Temperature Health Status Axle Three",
      filterable: false,
    },
    {
      key: "tpms.tireTemperatureRIHealthStatus[2]",
      label: "Tire RI Temperature Health Status Axle Three",
      filterable: false,
    },
    {
      key: "tpms.rightImbalance[2]",
      label: "Axle Three Right Imbalance",
      type: "number",
      filterable: false,
    },
    {
      key: "tpms.leftImbalance[2]",
      label: "Axle Three Left Imbalance",
      type: "number",
      filterable: false,
    },
    {
      key: "tpms.overallTPMSHealthStatus",
      label: "Overall TPMS Health Status",
    },
    {
      key: "tpms.overallTPMSTemperatureHealthStatus",
      label: "Overall TPMS Temperature Health Status",
    },
    {
      key: "tpms.overallTPMSPressureHealthStatus",
      label: "Overall TPMS Pressure Health Status",
    },
    {
      key: "tpms.overallTPMSImbalanceHealthStatus",
      label: "Overall TPMS Imbalance Health Status",
    },
    {
      key: "psiWheelEnd.leftWheelEndTemperature",
      label: "Left Wheel End Temperature",
      type: "number",
    },
    {
      key: "psiWheelEnd.rightWheelEndTemperature",
      label: "Right Wheel End Temperature",
      type: "number",
    },
    {
      key: "psiWheelEnd.leftWheelEndStatus",
      label: "Left Wheel End Health Status",
    },
    {
      key: "psiWheelEnd.rightWheelEndStatus",
      label: "Right Wheel End Health Status",
    },
    { key: "psiWheelEnd.wheelEndStatus", label: "Wheel End Health Status" },
    { key: "lights.lightsStatus", label: "Lights Health Status" },
    {
      key: "lights.brakesLightOut",
      label: "Stop Light Out",
    },
    {
      key: "lights.rightTurnSignalLightOut",
      label: "Right Turn Signal Light Out",
    },
    {
      key: "lights.leftTurnSignalLightOut",
      label: "Left Turn Signal Light Out",
    },
    {
      key: "lights.markerLightOut",
      label: "Marker Light Out",
    },
    {
      key: "lights.licenseLightOut",
      label: "License Light Out",
    },
    {
      key: "psiAirSupply.airSupplyHealthStatus",
      label: "Air Supply Health Status",
    },
    {
      key: "psiAirSupply.airTankPressure",
      label: "Air Tank Pressure",
      type: "number",
    },
    {
      key: "psiAirSupply.airTankHealthStatus",
      label: "Air Tank Health Status",
    },
    { key: "psiAirSupply.regulator", label: "Regulator", type: "number" },
    {
      key: "psiAirSupply.regulatorHealthStatus",
      label: "Regulator Health Status",
    },
    { key: "airBag.healthStatus", label: "Air Bag Health Status" },
    {
      key: "airBag.leftAirBagPressure",
      label: "Left Air Bag Tire Pressure",
      type: "number",
    },
    {
      key: "airBag.rightAirBagPressure",
      label: "Right Air Bag Tire Pressure",
      type: "number",
    },
    { key: "cargo.cargoState", label: "Cargo State" },
    {
      key: "internalCamera.healthStatus",
      label: "Internal Camera Status",
    },
    {
      key: "internalCamera.floorUsage",
      label: "Internal Camera Floor %",
      type: "number",
    },
    { key: "liftgate.healthStatus", label: "Liftgate Health Status" },
    { key: "liftgate.voltage", label: "Liftgate Voltage", type: "number" },
    {
      key: "overallLightOutHealthStatus",
      sortable: false,
      filterable: false,
      label: "Overall Light Out Health Status",
    },
    {
      key: "ultrasonic.distanceMeasurement",
      sortable: false,
      filterable: false,
      label: "Ultrasonic Sensor - Distance Measurement",
    },
    {
      key: "ultrasonic.statusUltrasonic",
      sortable: false,
      label: "Ultrasonic Sensor - Status",
    },
    {
      key: "ultrasonic.readingDate",
      label: "Ultrasonic Sensor - Reading Age",
    },
    {
      key: "healthSensors",
      sortable: false,
      filterable: false,
      label: "Indicators Healthy",
    },
    {
      key: "unhealthySensors",
      sortable: false,
      filterable: false,
      label: "Indicators Alert",
    },
  ];

  const sensorColumns = sensorFields.map((field) => ({
    ...(typeof field.filterable === "boolean" && {
      filterable: field.filterable,
    }),
    field: field.key,
    headerName: field.label,
    flex: 1,
    minWidth: 120,
    format: ReportColumnFormat.String,
    sortable: field.sortable ?? true,
    filterable: field.filterable ?? true,
    type: field.type ?? "string",
    valueGetter: (params: GridValueGetterParams<any, SensorsReportDataRow>) => {
      if (
        field.key === "psiWheelEnd.leftWheelEndTemperature" ||
        field.key === "psiWheelEnd.rightWheelEndTemperature"
      ) {
        return getColumnValue(params, field.key)?.map(
          (value: number | null) => value ?? "-"
        );
      }
      return getColumnValue(params, field.key);
    },
  }));

  return [...assetColumns, ...sensorColumns];
};

export const SEARCH_KEYS = [
  "assetId",
  "assetName",
  "imei",
  "voltage.primaryVoltage",
  "voltage.secondaryVoltage",
  "voltage.solarBatteryVoltage",
  "voltage.batteryVoltage",
  "voltage.batteryHealthStatus",
  "voltage.voltageHealthStatus",
  "bleDoor.doorType",
  "bleDoor.doorSensorStatus",
  "abs.faultCount",
  "odometer.odometerKmph",
  "atis.healthStatus",
  "atis.lightActivatedNumber",
  "atis.lightActivatedSeconds",
  "temperature.ambient",
  "temperature.internal",
  "temperature.healthStatus",
  "tpms.tpmsStatus",
  "tpms.tirePressureLO[0]",
  "tpms.tirePressureLI[0]",
  "tpms.tirePressureRI[0]",
  "tpms.tirePressureRO[0]",
  "tpms.tireTemperatureLO[0]",
  "tpms.tireTemperatureLI[0]",
  "tpms.tireTemperatureRI[0]",
  "tpms.tireTemperatureRO[0]",
  "tpms.axleHealthStatus[0]",
  "tpms.tireLOHealthStatus[0]",
  "tpms.tireLIHealthStatus[0]",
  "tpms.tireROHealthStatus[0]",
  "tpms.tireRIHealthStatus[0]",
  "tpms.tirePressureLOHealthStatus[0]",
  "tpms.tirePressureLIHealthStatus[0]",
  "tpms.tirePressureROHealthStatus[0]",
  "tpms.tirePressureRIHealthStatus[0]",
  "tpms.tireTemperatureLOHealthStatus[0]",
  "tpms.tireTemperatureLIHealthStatus[0]",
  "tpms.tireTemperatureROHealthStatus[0]",
  "tpms.tireTemperatureRIHealthStatus[0]",
  "tpms.rightImbalance[0]",
  "tpms.leftImbalance[0]",
  "tpms.tirePressureLO[1]",
  "tpms.tirePressureLI[1]",
  "tpms.tirePressureRI[1]",
  "tpms.tirePressureRO[1]",
  "tpms.tireTemperatureLO[1]",
  "tpms.tireTemperatureLI[1]",
  "tpms.tireTemperatureRI[1]",
  "tpms.tireTemperatureRO[1]",
  "tpms.axleHealthStatus[1]",
  "tpms.tireLOHealthStatus[1]",
  "tpms.tireLIHealthStatus[1]",
  "tpms.tireROHealthStatus[1]",
  "tpms.tireRIHealthStatus[1]",
  "tpms.tirePressureLOHealthStatus[1]",
  "tpms.tirePressureLIHealthStatus[1]",
  "tpms.tirePressureROHealthStatus[1]",
  "tpms.tirePressureRIHealthStatus[1]",
  "tpms.tireTemperatureLOHealthStatus[1]",
  "tpms.tireTemperatureLIHealthStatus[1]",
  "tpms.tireTemperatureROHealthStatus[1]",
  "tpms.tireTemperatureRIHealthStatus[1]",
  "tpms.rightImbalance[1]",
  "tpms.leftImbalance[1]",
  "tpms.tirePressureLO[2]",
  "tpms.tirePressureLI[2]",
  "tpms.tirePressureRI[2]",
  "tpms.tirePressureRO[2]",
  "tpms.tireTemperatureLO[2]",
  "tpms.tireTemperatureLI[2]",
  "tpms.tireTemperatureRI[2]",
  "tpms.tireTemperatureRO[2]",
  "tpms.axleHealthStatus[2]",
  "tpms.tireLOHealthStatus[2]",
  "tpms.tireLIHealthStatus[2]",
  "tpms.tireROHealthStatus[2]",
  "tpms.tireRIHealthStatus[2]",
  "tpms.tirePressureLOHealthStatus[2]",
  "tpms.tirePressureLIHealthStatus[2]",
  "tpms.tirePressureROHealthStatus[2]",
  "tpms.tirePressureRIHealthStatus[2]",
  "tpms.tireTemperatureLOHealthStatus[2]",
  "tpms.tireTemperatureLIHealthStatus[2]",
  "tpms.tireTemperatureROHealthStatus[2]",
  "tpms.tireTemperatureRIHealthStatus[2]",
  "tpms.rightImbalance[2]",
  "tpms.leftImbalance[2]",
  "tpms.overallTPMSHealthStatus",
  "tpms.overallTPMSTemperatureHealthStatus",
  "tpms.overallTPMSPressureHealthStatus",
  "tpms.overallTPMSImbalanceHealthStatus",
  "psiWheelEnd.leftWheelEndTemperature",
  "psiWheelEnd.rightWheelEndTemperature",
  "psiWheelEnd.leftWheelEndStatus",
  "psiWheelEnd.rightWheelEndStatus",
  "psiWheelEnd.wheelEndStatus",
  "lights.lightsStatus",
  "lights.brakesLightOut",
  "lights.rightTurnSignalLightOut",
  "lights.leftTurnSignalLightOut",
  "lights.markerLightOut",
  "lights.licenseLightOut",
  "psiAirSupply.airSupplyHealthStatus",
  "psiAirSupply.airTankPressure",
  "psiAirSupply.airTankHealthStatus",
  "psiAirSupply.regulator",
  "psiAirSupply.regulatorHealthStatus",
  "airBag.healthStatus",
  "airBag.leftAirBagPressure",
  "airBag.rightAirBagPressure",
  "cargo.cargoState",
  "internalCamera.healthStatus",
  "internalCamera.floorUsage",
  "liftgate.healthStatus",
  "liftgate.voltage",
  "unhealthySensors",
  "healthSensors",
  "ultrasonic.readingDate",
  "ultrasonic.statusUltrasonic",
  "ultrasonic.distanceMeasurement",
  "overallLightOutHealthStatus",
];
