import {
  FC,
  memo,
  MutableRefObject,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Box } from "@mui/system";
import { GridApiPremium } from "@mui/x-data-grid-premium/models/gridApiPremium";
import { useAppContext } from "../../../../../context/AppContext";
import {
  SensorProfileResult,
  TableViewType,
} from "../../../../../graphql/operations";
import { getRows, Table } from "../../../../../shared/components/Table";
import { useExportedFileName } from "../../../../../shared/hooks/useExportedFileName";
import { useGetPressureUnitPreference } from "../../../../../shared/hooks/useGetPressureUnitPreference";
import { TAB_WITH_SIDE_PANEL_BREAKPOINT } from "../../constants";
import {
  columnVisibilityModel,
  ProfilesData,
  pressureTableKeyList,
  mapTireSensorProfileData,
  pressureTableColumns,
  MappedSensorProfile,
} from "../profileUtils";

export interface TirePressureProfileTableProps {
  tableType: TableViewType;
  onRowClick: (value: any) => void;
  apiRef: MutableRefObject<GridApiPremium>;
  profilesData: ProfilesData;
}

const TirePressureProfileTable: FC<TirePressureProfileTableProps> = ({
  tableType,
  onRowClick,
  apiRef,
  profilesData,
}) => {
  const {
    state: { appConfig },
  } = useAppContext();
  const fileName = useExportedFileName("Profiles");
  const pressureUnit = useGetPressureUnitPreference();

  const [tableRows, setTableRows] = useState<SensorProfileResult[]>([]);

  useEffect(() => {
    if (profilesData.data) {
      const mappedProfiles = mapTireSensorProfileData(
        profilesData.data,
        pressureUnit
      );
      setTableRows(
        getRows<MappedSensorProfile[]>(
          mappedProfiles,
          pressureTableColumns,
          "",
          "",
          "_id"
        )
      );
    }
  }, [profilesData.data, pressureUnit]);

  return (
    <Box className="flex-1 basis-[300px]">
      <Table
        tableType={tableType}
        showToolbar
        rows={tableRows}
        apiRef={apiRef}
        columns={pressureTableColumns}
        columnVisibilityModel={columnVisibilityModel}
        onRowClick={onRowClick}
        loading={profilesData.isFetchingProfile}
        error={profilesData.isErrorProfile}
        searchThreshold={0.1}
        searchExactMatch
        enableSearch
        initialSearch=""
        searchKeys={pressureTableKeyList}
        sortKeys={pressureTableKeyList}
        searchMinLength={appConfig.searchMinLength}
        tableName="tpms-profiles"
        exportProps={{
          csvOptions: { fileName },
          excelOptions: { fileName },
          printOptions: { fileName },
        }}
        isDataRefetching={false}
        mobileBreakpoint={TAB_WITH_SIDE_PANEL_BREAKPOINT}
      />
    </Box>
  );
};

export default memo(TirePressureProfileTable);
