import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { isNil, omitBy } from "lodash";
import * as yup from "yup";
import { getAlertCommonSchema } from "../utils";

export const useAlertGeofenceForm = (
  initialValues = {
    name: "",
    type: "",
    parameters: {
      region: [],
      zone: [],
      geofenceType: "",
      geofenceIds: [],
      alertOnEnter: false,
      alertOnExit: false,
    },
    reminders: [
      {
        schedule: {
          startDate: "",
          endDate: "",
          timeFrom: "",
          timeFormatFrom: "",
          timeTo: "",
          timeFormatTo: "",
          days: [],
        },
        subscribers: { roles: [], users: [], emails: [] },
      },
    ],
    message: "",
    trigger: null,
  }
) => {
  const alertCommonSchema = getAlertCommonSchema();

  const schema = yup.object().shape({
    parameters: yup.object().when(["saveAsNewAlertsType"], {
      is: false,
      then: (scheme) =>
        scheme.shape({
          region: yup.array().of(yup.string()).optional(),
          zone: yup.array().of(yup.string()).optional(),
          geofenceType: yup.string().nullable().optional(),
          geofenceIds: yup
            .array()
            .of(
              yup.object().shape({
                id: yup.string(),
                label: yup.string(),
              })
            )
            .optional(),
          alertOnEnter: yup.boolean().optional(),
          alertOnExit: yup.boolean().optional(),
        }),
    }),
    ...alertCommonSchema,
  });

  const form = useForm({
    resolver: yupResolver(schema),
    mode: "onBlur",
    values: omitBy(
      {
        ...initialValues,
        saveAsNewAlertsType: false,
        alertTemplateOrgIds: [],
      },
      isNil
    ),
  });

  const getValues = useCallback(
    () => schema.cast(form.getValues(), { assert: false }),
    [form, schema]
  );

  return { form, getValues, schema };
};
