import { FC, useEffect, useMemo, useState, useCallback } from "react";
import { Box, Typography } from "@mui/material";
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Label,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  LabelProps,
  XAxisProps,
  YAxisProps,
} from "recharts";
import { ColorsPalette } from "../../../../design-system/colors-palette";
import {
  useGetAlertHistoryGraphDataQuery,
  GetAlertHistoryGraphDataInput,
  TableFilterLinkOperator,
  TableValueDataType,
  TableFilterOperator,
  AlertHistoryGraphData,
} from "../../../../graphql/operations";
import DateRangePicker from "../../../../shared/components/DateRangePicker";
import useBreakpoint from "../../../../shared/hooks/useBreakpoint";
import {
  DAY_MONTH_FORMAT,
  formatDateToUTC,
  getEndOfToday,
  getStartOfDay,
  getSubDays,
} from "../../../../utils";
import { AlertsHistogramSkeleton } from "../../../AlertsView/components/AlertsHistogram/AlertsHistogramSkeleton";
import {
  ChartTooltip,
  ChartType,
} from "../../../AssetsView/MapView/Shared/ChartTooltip/ChartTooltip";

export type AlertHistoryGraphProps = {
  alertId: string;
};

const containerHeight = 330;

const chartTickStyle = {
  fontSize: 10,
  fill: "var(--chart-tick-style)",
  fontWeight: 500,
};

export const AlertHistoryGraph: FC<AlertHistoryGraphProps> = ({ alertId }) => {
  const isMobile = useBreakpoint("down", "sm");

  const [chartData, setChartData] = useState<AlertHistoryGraphData[]>([]);
  const [showNoData, setShowNoData] = useState<boolean>(false);

  const [startDate, setStartDate] = useState<Date | null>(
    getStartOfDay(getSubDays(new Date(), 30))
  );
  const [endDate, setEndDate] = useState<Date | null>(getEndOfToday());

  // Calculate the width of the yAxis based on the number of digits in the largest incident count
  const yAxisWidth = useMemo(() => {
    const biggestCountLength = chartData.reduce(
      (max, item) => Math.max(max, item?.value?.toString().length),
      0
    );
    return biggestCountLength > 1 ? biggestCountLength * 9 : 9;
  }, [chartData]);

  const queryInput: GetAlertHistoryGraphDataInput = useMemo(() => {
    const dateValue = (dateObj: Date | null) => {
      if (!dateObj) return '{"value": null}';
      const formattedDate = dateObj.toISOString();
      return `{"value": "${formattedDate}"}`;
    };

    return {
      alertId,
      filters: {
        linkOperator: TableFilterLinkOperator.And,
        filters: [
          {
            dataType: TableValueDataType.Date,
            operator: TableFilterOperator.Gte,
            field: "startedAt",
            value: dateValue(startDate),
          },
          {
            dataType: TableValueDataType.Date,
            operator: TableFilterOperator.Lte,
            field: "startedAt",
            value: dateValue(endDate),
          },
          {
            dataType: TableValueDataType.Date,
            operator: TableFilterOperator.Gte,
            field: "endedAt",
            value: dateValue(startDate),
          },
          {
            dataType: TableValueDataType.Date,
            operator: TableFilterOperator.Lte,
            field: "endedAt",
            value: dateValue(endDate),
          },
        ],
      },
    };
  }, [alertId, startDate, endDate]);

  const { data, isLoading } = useGetAlertHistoryGraphDataQuery(
    {
      input: queryInput,
    },
    { enabled: Boolean(alertId) }
  );

  useEffect(() => {
    if (data?.getAlertHistoryGraphData?.data) {
      const filteredData: AlertHistoryGraphData[] =
        data.getAlertHistoryGraphData.data.filter(
          (item: AlertHistoryGraphData): item is AlertHistoryGraphData =>
            item !== null
        );

      setChartData(filteredData);
      setShowNoData(!isLoading && !filteredData.length);
    } else if (isLoading) {
      // The data is refetching, so we remove the old data and show the skeleton loader
      setChartData([]);
    }
  }, [data?.getAlertHistoryGraphData?.data, isLoading]);

  const handleDateRangeChange = useCallback(
    (startDate: Date | null, endDate: Date | null) => {
      setStartDate(startDate);
      setEndDate(endDate);
    },
    [setStartDate, setEndDate]
  );

  const labelProps: Partial<LabelProps> = {
    value: "No data available",
    position: "center",
    style: { transform: `translate(0px, -50%)` },
  };

  const xAxisProps: Partial<XAxisProps> = {
    dataKey: "date",
    tick: chartTickStyle,
    tickLine: false,
    tickFormatter: (tickItem: string) =>
      formatDateToUTC(new Date(tickItem)).toFormat(DAY_MONTH_FORMAT),
  };

  const yAxisProps: Partial<YAxisProps> = {
    yAxisId: "alert-history-graph",
    type: "number",
    orientation: "left",
    tick: chartTickStyle,
    width: yAxisWidth,
  };

  if (isMobile) {
    return null;
  }

  return (
    <Box
      data-testid="alert-history-graph-wrapper"
      className="w-full px-4 pt-8 md:px-6 md:pt-0 lg:px-16 flex flex-col gap-6"
    >
      <Box
        data-testid="alert-history-graph-header"
        className="flex justify-between"
      >
        <Typography sx={{ color: "var(--header-text)", fontWeight: 600 }}>
          Total Number of Alerts
        </Typography>

        <DateRangePicker
          defaultStartDate={startDate}
          defaultEndDate={endDate}
          onChange={handleDateRangeChange}
        />
      </Box>

      <Box
        data-testid="alert-history-graph-chart"
        className="relative"
        style={{ height: containerHeight }}
      >
        {isLoading && (
          <Box className="absolute bottom-9 left-20">
            <AlertsHistogramSkeleton />
          </Box>
        )}

        {showNoData && (
          <Typography className="text-header-text !font-medium absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            No data available
          </Typography>
        )}

        <ResponsiveContainer>
          <ComposedChart
            data={chartData}
            barSize={24}
            barGap={0}
            stackOffset="none"
          >
            <Label {...labelProps} />
            <CartesianGrid stroke={ColorsPalette.Concrete} />
            <XAxis {...xAxisProps} />
            <YAxis {...yAxisProps} />
            <Tooltip
              offset={-5}
              content={<ChartTooltip type={ChartType.AlertsHistogram} />}
            />
            <Bar
              yAxisId="alert-history-graph"
              dataKey="value"
              fill={ColorsPalette.PrimaryBlue}
              stackId="a"
            />
          </ComposedChart>
        </ResponsiveContainer>
      </Box>
    </Box>
  );
};
