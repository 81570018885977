import { FC, memo, useCallback, useEffect, useMemo, useState } from "react";
import { Autocomplete, Button, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { GridSortModel } from "@mui/x-data-grid-premium";
import { useQueryClient } from "@tanstack/react-query";
import { BatchTitles } from "../../../../constants/batches";
import { useAppContext } from "../../../../context/AppContext";
import { useAuthContext } from "../../../../context/AuthContext";
import {
  AssetWithSensors,
  ProfileConfigProperty,
  SensorProfileConfigType,
} from "../../../../graphql/operations";
import { UploadSensorProfilesDialog } from "../../../../shared/components/SensorProfilesUploadDialog/UploadSensorProfilesDialog";
import { WithPermissions } from "../../../../shared/components/WithPermissions";
import { useGetAssetsWithSensorProfiles } from "../../../../shared/hooks/openSearchMongoPolyfillHooks/useGetAssetsWithSensorProfiles";
import { useAvailableOrgs } from "../../../../shared/hooks/useAvailableOrgs";
import useBreakpoint from "../../../../shared/hooks/useBreakpoint";
import { useCurrentOrg } from "../../../../shared/hooks/useCurrentOrg";
import { useGetPressureUnitPreference } from "../../../../shared/hooks/useGetPressureUnitPreference";
import {
  BATCH_FORM_FIELDS,
  BatchFormFieldsNames,
  mapOrgs,
} from "../../../BatchesView/BatchManagementUtils";
import SidePanel from "../../components/SidePanel";
import SensorsDrawerGateway from "./SensorsDrawerGateway";
import SensorsTableGateway from "./SensorsTableGateway";
import { SensorsGatewayTypes, mapAssetsSensorsData } from "./sensorsUtils";
import { SensorsSideMenuOptions } from "./types";

//TODO replace any with the actual types
export type SensorsTablePaginationProps = {
  onFilterChange: (value: any) => void;
  onSortModelChange: (value: any) => void;
  onPageChange: (value: any) => void;
  refetchingData: boolean;
  totalTableRowsCount: number;
};

const Sensors: FC = () => {
  const {
    state: {
      selectedOrganization: { selectedOrganization },
    },
  } = useAppContext();
  const dataCurrentOrg = useCurrentOrg();
  const availableOrgs = useAvailableOrgs();
  const { userRolePermissions } = useAuthContext();
  const queryClient = useQueryClient();
  const pressureUnit = useGetPressureUnitPreference();

  const [selectedRecordsData, setSelectedRecordsData] = useState<any[]>([]);
  const [indexState, setIndexState] = useState<number>();
  const [selectedSideMenuOption, setSelectedSideMenuOption] = useState<string>(
    SensorProfileConfigType.Airbag
  );
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [sortModel, setSortModel] = useState<GridSortModel>([]);
  const [currentPageNo, setCurrentPageNo] = useState<number>(1);
  const [totalTableRowsCount, setTotalTableRowsCount] = useState<number>(0);
  const [usersPagination, setUsersPagination] = useState<number>(0);
  const sensorsPerPage = 25; // todo extract in appConfig.ts like state.appConfig.table;
  const [
    isUploadSensorProfilesDialogOpen,
    setIsUploadSensorProfilesDialogOpen,
  ] = useState(false);
  const {
    data: sensorsData,
    isError: isErrorSensor,
    isFetching: isFetchingSensor,
    refetch: refetchAssets,
  } = useGetAssetsWithSensorProfiles(
    {
      orgId: selectedOrganization.value ?? "",
      sensorType: selectedSideMenuOption,
      skip: usersPagination,
      limit: sensorsPerPage,
    },
    selectedOrganization.value
  );
  const sensorsDataItems = useMemo(() => {
    const data = (sensorsData?.assets ?? []) as AssetWithSensors[];

    return mapAssetsSensorsData(data, pressureUnit);
  }, [sensorsData, pressureUnit]);

  useEffect(() => {
    if (sensorsData?.total) {
      setTotalTableRowsCount(sensorsData?.total as number);
    }
  }, [sensorsData]);

  const panelOptions = [
    {
      title: SensorsSideMenuOptions.Battery,
      type: SensorProfileConfigType.Voltage,
      visible: true,
      adornment: String(sensorsData?.batteryCount ?? ""),
    },
    {
      title: SensorsSideMenuOptions.TPMS,
      type: SensorProfileConfigType.TpmsBeta,
      visible: true,
      adornment: String(sensorsData?.tpmsCount ?? ""),
    },
    {
      title: SensorsSideMenuOptions.Camera,
      type: SensorProfileConfigType.CargoCamera,
      visible: true,
      adornment: String(sensorsData?.cargoCameraCount ?? ""),
    },
    {
      title: SensorsSideMenuOptions.AirSupply,
      type: ProfileConfigProperty.AirSupply,
      visible: true,
      adornment: String(sensorsData?.airSupplyCount ?? ""),
    },
    {
      title: SensorsSideMenuOptions.AirBag,
      type: SensorProfileConfigType.Airbag,
      visible: true,
      adornment: String(sensorsData?.airBagCount ?? ""),
    },
    {
      title: SensorsSideMenuOptions.WheelEnd,
      type: SensorProfileConfigType.PsiWheelEnd,
      visible: true,
      adornment: String(sensorsData?.wheelEndCount ?? ""),
    },
    {
      title: SensorsSideMenuOptions.ATIS,
      type: SensorProfileConfigType.AtisAlpha,
      visible: true,
      adornment: String(sensorsData?.atisAlphaCount ?? ""),
    },
    {
      title: SensorsSideMenuOptions.Temperature,
      type: ProfileConfigProperty.Internal,
      visible: true,
      adornment: String(sensorsData?.temperatureCount ?? ""),
    },
    {
      title: SensorsSideMenuOptions.LightCircuit,
      type: SensorProfileConfigType.LiteSentryGamma,
      visible: true,
      adornment: String(sensorsData?.liteSentryGammaCount ?? ""),
    },
    {
      title: SensorsSideMenuOptions.Liftgate,
      type: SensorProfileConfigType.Liftgate,
      visible: true,
      adornment: String(sensorsData?.liftgateCount ?? ""),
    },
  ];

  panelOptions.sort((a, b) => a.title.localeCompare(b.title));

  const firstTab = dataCurrentOrg
    ? panelOptions.findIndex((option) => option.visible)
    : 0;

  const handleEditSensor = (selection: any) => {
    if (userRolePermissions.sensors.edit) {
      let currentSelectedRows = selectedRows;
      //if we have selected some records but clicked over one who is not selected - unselect selected records and select only the clicked one
      if (selectedRows.indexOf(selection.id) === -1) {
        currentSelectedRows = [selection.id];

        setSelectedRows(() => currentSelectedRows);
      }
      const selectedRecords = sensorsDataItems.filter(
        (obj: AssetWithSensors | null) => currentSelectedRows.includes(obj?._id)
      );
      queryClient.invalidateQueries([
        "useGetSensorProfileNamesWithConfigurationQuery",
      ]);
      setSelectedRecordsData(selectedRecords);
      setIsDrawerOpen(true);
    }
  };

  useEffect(() => {
    if (!isDrawerOpen) {
      setSelectedRecordsData([]);
    }
  }, [isDrawerOpen]);

  // Re-trigger asset request on sidemenu option change
  useEffect(() => {
    if (!isErrorSensor && !isFetchingSensor) refetchAssets();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetchAssets, selectedSideMenuOption]);

  const isMobile = useBreakpoint("down", "sm");
  const actionList = panelOptions.filter((option) => !!option.visible);
  const actionListOptions = actionList.map((option) => option.title);

  const changeSensorFromMenu = (index: number) => {
    setSelectedRows([]);
    setSelectedRecordsData([]);
    setIndexState(index);
    setSelectedSideMenuOption(panelOptions[index].type);
    setUsersPagination(0);
  };

  // option change from
  const changeSensorFromMobile = (eventValue: string) => {
    const optionIndex = Number(
      panelOptions.findIndex((option) => option.title === eventValue)
    );
    setIndexState(optionIndex);
    setSelectedSideMenuOption(panelOptions[optionIndex].type);
    setUsersPagination(0);
  };

  // ADD sensors table serverside operations
  const onFilterChange = (data: any) => {
    //TODO
  };

  const onSortModelChange = useCallback(
    (sortModel: GridSortModel) => {
      setSortModel(sortModel);
    },
    [setSortModel]
  );

  const onPageChange = useCallback(
    (page: number) => {
      setCurrentPageNo(page);
      setUsersPagination(sensorsPerPage * (page - 1));
    },
    [setCurrentPageNo, setUsersPagination, sensorsPerPage]
  );

  const uploadSensorProfilesHandler = () => {
    setIsUploadSensorProfilesDialogOpen(true);
  };

  const onUploadSensorProfilesDialogClose = () => {
    setIsUploadSensorProfilesDialogOpen(false);
  };

  const orgs = mapOrgs(availableOrgs ?? []);
  BATCH_FORM_FIELDS[BatchFormFieldsNames.AddToOrganization].values = orgs;

  return (
    <Box data-testid="tab-sensors" className="bg-background pt-2 h-[90%]">
      <WithPermissions accessScope="sensors.create">
        <Button
          className={"global-btn !py-1 !text-sm !text-primary"}
          variant="text"
          type="button"
          onClick={uploadSensorProfilesHandler}
          sx={{
            display: "block",
            margin: "10px 0",
            marginLeft: "auto",
            border: "solid",
            borderWidth: "2px",
            fontWeight: "bold",
          }}
        >
          Upload
        </Button>
      </WithPermissions>
      <Box className="flex flex-wrap h-full gap-4">
        <SidePanel
          sx={{
            flexGrow: isMobile ? 1 : 0,
          }}
          key={String(dataCurrentOrg)}
          actionList={actionList}
          defaultActive={indexState}
          onActionClick={(index) => changeSensorFromMenu(index)}
        >
          {isMobile ? (
            <Autocomplete
              sx={{ marginBottom: "1rem" }}
              disableClearable
              options={actionListOptions}
              defaultValue={actionListOptions[firstTab]}
              onChange={(_event, value) => {
                changeSensorFromMobile(value);
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          ) : undefined}
        </SidePanel>

        <SensorsTableGateway
          type={selectedSideMenuOption as unknown as SensorsGatewayTypes}
          orgId={selectedOrganization.value}
          onRowClick={handleEditSensor}
          onRowSelect={setSelectedRows}
          sensorsData={{
            data: sensorsDataItems,
            isErrorSensor,
            isFetchingSensor,
          }}
          //this prop is passed because of the case where we have some selection and user click over row who is not selected - in this case deselect all selected rows and select only cliced one
          rowSelectionModel={selectedRows}
          // pagination props
          onFilterChange={onFilterChange}
          onSortModelChange={onSortModelChange}
          onPageChange={onPageChange}
          refetchingData={isFetchingSensor}
          totalTableRowsCount={totalTableRowsCount}
        />
        {isUploadSensorProfilesDialogOpen && (
          <UploadSensorProfilesDialog
            title={BatchTitles.AssignSensorProfiles}
            customerOrg={dataCurrentOrg?.name}
            dialogFields={BATCH_FORM_FIELDS}
            isOpen={isUploadSensorProfilesDialogOpen}
            onClose={onUploadSensorProfilesDialogClose}
            onUpload={() => queryClient.invalidateQueries(["getBatchHistory"])}
          />
        )}
        {isDrawerOpen && (
          <SensorsDrawerGateway
            type={selectedSideMenuOption as unknown as SensorsGatewayTypes}
            currentOrgId={selectedOrganization.value}
            selectedRecordsData={selectedRecordsData}
            open={isDrawerOpen}
            setOpen={setIsDrawerOpen}
          />
        )}
      </Box>
    </Box>
  );
};

// we should add displayName property manually to memoized components because
// there is no other way to access the name of the component if we need it
Sensors.displayName = "Sensors";
export default memo(Sensors);
