import { FC, SyntheticEvent, useEffect, useMemo, useState } from "react";
import { AutocompleteElement, useForm } from "react-hook-form-mui";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Grid,
  Input,
  Typography,
  ThemeProvider,
  FormHelperText,
} from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { ReactComponent as TemperatureSensorDark } from "../../../../../../assets/svgs/temperatureSensorDark.svg";
import { ReactComponent as TemperatureSensorLight } from "../../../../../../assets/svgs/temperatureSensorLight.svg";
import { PAGE_SNACKBAR } from "../../../../../../constants";
import { useAppContext } from "../../../../../../context/AppContext";
import {
  AssetWithSensors,
  AssignOrgProfileToAssetsInput,
  SensorProfileType,
  SensorProfileConfigType,
  SetAssetSensorProfilesInput,
  useGetSensorProfileNamesWithConfigurationQuery,
  TemperatureInternalThresholds,
  SensorConfigInputTempInternal,
} from "../../../../../../graphql/operations";
import { ConfirmationDialog } from "../../../../../../shared/components/ConfirmationDialog";
import Drawer from "../../../../../../shared/components/Drawer";
import DrawerContent from "../../../../../../shared/components/Drawer/DrawerContent";
import DrawerFooter from "../../../../../../shared/components/Drawer/DrawerFooter";
import DrawerHeader from "../../../../../../shared/components/Drawer/DrawerHeader";
import SensorSlider from "../../../../../../shared/components/SensorSlider/SensorSlider";
import {
  sliderMarks,
  extractDoubleRules,
  prepareDoubleRulesPayload,
  returnEvenValuesFromRange,
  returnDynamicLimit,
  internalTemperatureDefaultValues,
} from "../../../../../../shared/components/SensorSlider/sensorSliderUtils";
import Text from "../../../../../../shared/components/Text";
import { SelectElement } from "../../../../../../shared/components/react-hook-form-mui/SelectElement";
import {
  MinValuesBySensorType,
  MaxValuesBySensorType,
} from "../../../../../../shared/helpers/battery";
import {
  HEALTHY_POINT_MAX,
  HEALTHY_POINT_MIN,
} from "../../../../../../shared/helpers/temperature";
import { useFormTheme } from "../../../../../../shared/hooks/theme/useFormTheme";
import { mapServerErrorCodeToHumanReadableMessage } from "../../../../../../utils";
import {
  SensorProfileDropdownItem,
  SensorProfileDropdownItemWithId,
  sortSensorProfileNameDropdownItems,
} from "../../sensorsUtils";
import SelectedRecordInfo from "../../shared/SelectedRecordsInfo";
import ConfigurationDialogAdditionalElement from "../Components/CheckboxComponent";
import { useMutateCustomProfile, useMutateProfile } from "../drawer.hooks";
import {
  TEMPERATURE_FORM_FIELDS,
  TemperatureFormFieldNames,
} from "./constants";
import { sensorsTemperatureSchema } from "./validationSchema";

export interface TemperatureDrawerProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  selectedRecordsData?: AssetWithSensors[];
  currentOrgId?: string;
}

const TemperatureDrawer: FC<TemperatureDrawerProps> = ({
  open,
  setOpen,
  selectedRecordsData,
  currentOrgId,
}) => {
  const mainFormTheme = useFormTheme();
  const queryClient = useQueryClient();
  const {
    dispatch,
    state: { theme },
  } = useAppContext();
  const isLightTheme = theme.theme === "light";
  const svgIconSettings = {
    width: "2.5rem",
    height: "2.5rem",
    display: "block",
    margin: "0.75rem 0rem",
  };

  const min = MinValuesBySensorType.internal;
  const max = MaxValuesBySensorType.internal;

  const temperature =
    selectedRecordsData?.[0]?.sensorProfile?.configuration?.temperature;
  const isAssetType = temperature?.type === SensorProfileType.Asset;
  const defaultTemperatureSensorProfile = !isAssetType
    ? temperature?.profileId
    : undefined;

  // Sensor values extraction
  const temperatureConfig =
    selectedRecordsData?.[0]?.temperatureSensor?.internal;
  const parsedConfig = extractDoubleRules(
    temperatureConfig,
    "internal" as SensorProfileConfigType
  );
  const initialTemperature = parsedConfig || internalTemperatureDefaultValues;
  const initialCold = initialTemperature.slice(0, 3);
  const initialHealthy = initialTemperature.slice(3, 4)[0];
  const initialHot = initialTemperature.slice(4, 7);

  // State
  const [coldTemperature, setColdTemperature] = useState(initialCold);
  const [hotTemperature, setHotTemperature] = useState(initialHot);
  const [healthyPoint, setHealthyPoint] = useState(initialHealthy);
  const [healthyPointError, setHealthyPointError] = useState<string>("");
  const [profileInput, setProfileInput] = useState<
    AssignOrgProfileToAssetsInput | undefined
  >(undefined);
  const [selectedProfileLabel, setSelectedProfileLabel] = useState<string>("");
  const [isTypeOfAssignmentProfile, setIsTypeOfAssignmentProfile] =
    useState(false);
  const [dontShowConfirmationDialog, setDontShowConfirmationDialog] =
    useState(false);
  const [
    isConfirmProfileAssignmentVisible,
    setIsConfirmProfileAssignmentVisible,
  ] = useState(false);

  const mainForm = useForm({
    resolver: yupResolver(sensorsTemperatureSchema),
    defaultValues: {
      typeOfAssignment: isAssetType ? "custom" : "profile",
      temperatureSensorProfile: defaultTemperatureSensorProfile,
    },
  });

  useEffect(() => {
    if (!isAssetType) {
      setIsTypeOfAssignmentProfile(true);
    }
    const isDontShowAgain =
      localStorage.getItem("setProfileConformation") === "true";
    setDontShowConfirmationDialog(isDontShowAgain);
  }, [isAssetType]);

  // Handlers
  const handleClose = () => {
    setProfileInput(undefined);
    mainForm.reset();
    setOpen(false);
  };

  const dispatchSuccessMessage = () => {
    dispatch({
      type: PAGE_SNACKBAR,
      payload: {
        text: "Sensor(s) Updated Successfully!",
        severity: "success",
      },
    });
  };

  const dispatchErrorMessage = (message: string) => {
    dispatch({
      type: PAGE_SNACKBAR,
      payload: {
        title: "Settings Uploading Failed",
        text: mapServerErrorCodeToHumanReadableMessage(message),
        severity: "error",
        onClose: () => {},
      },
    });
  };

  const { data: profilesData, isLoading: isSensorProfileNamesLoading } =
    useGetSensorProfileNamesWithConfigurationQuery(
      {
        input: {
          orgId: currentOrgId ?? "",
          type: SensorProfileType.Organization,
          includeDefaultPctProfile: true,
          sensorType: SensorProfileConfigType.Temperature,
        },
      },
      {
        enabled: Boolean(currentOrgId),
      }
    );

  // this is a mutation that we use when Type of Assignment is CUSTOM
  const { mutate: mutateCustomProfile, isLoading: isLoadingCustomProfile } =
    useMutateCustomProfile({
      dispatchErrorMessage,
      dispatchSuccessMessage,
      queryClient,
      handleClose,
    });

  // this is a mutation that we use when Type of Assignment is PROFILE
  const { mutate: mutateProfile, isLoading: isLoadingProfile } =
    useMutateProfile({
      dispatchErrorMessage,
      dispatchSuccessMessage,
      queryClient,
      handleClose,
    });

  const onHealthyPointChange = (e: { target: { value: string } }) => {
    const num = Number(e.target.value);
    if (isNaN(num)) return;

    const defaultErrorMsg = `Number must be between ${HEALTHY_POINT_MIN} and ${HEALTHY_POINT_MAX}.`;
    let evenColdTemperature = returnEvenValuesFromRange(min, num);
    let evenHotTemperature = returnEvenValuesFromRange(num, max);
    let errorMessage = "";

    if (num < HEALTHY_POINT_MIN) {
      evenColdTemperature = returnEvenValuesFromRange(min, HEALTHY_POINT_MIN);
      evenHotTemperature = returnEvenValuesFromRange(HEALTHY_POINT_MIN, max);
      errorMessage = defaultErrorMsg;
    } else if (num > HEALTHY_POINT_MAX) {
      evenColdTemperature = returnEvenValuesFromRange(min, HEALTHY_POINT_MAX);
      evenHotTemperature = returnEvenValuesFromRange(HEALTHY_POINT_MAX, max);
      errorMessage = defaultErrorMsg;
    }

    setColdTemperature(evenColdTemperature);
    setHotTemperature(evenHotTemperature);
    setHealthyPoint(num);
    setHealthyPointError(errorMessage);
  };

  const handleAssignmentChange = (value: string) => {
    if (!value) return;

    setIsTypeOfAssignmentProfile(value === "profile");

    if (value === "profile") {
      setHealthyPointError("");
    } else if (isAssetType) {
      setColdTemperature(initialCold);
      setHotTemperature(initialHot);
      setHealthyPoint(initialHealthy);
    }
  };

  const handleSensorProfileInputChange = (
    event: SyntheticEvent<Element, Event>,
    selectedSensorProfile: SensorProfileDropdownItem
  ) => {
    const selectedProfileValues =
      extractDoubleRules(
        selectedSensorProfile?.configuration?.temperature
          ?.internal as TemperatureInternalThresholds,
        "internal" as SensorProfileConfigType
      ) || internalTemperatureDefaultValues;
    const selectedProfileColdTemperature = selectedProfileValues.slice(0, 3);
    const selectedProfileHealthyPoint = selectedProfileValues.slice(3, 4)[0];
    const selectedProfileHotTemperature = selectedProfileValues.slice(4, 7);

    setColdTemperature(selectedProfileColdTemperature);
    setHotTemperature(selectedProfileHotTemperature);
    setHealthyPoint(selectedProfileHealthyPoint);

    mainForm.trigger();
  };

  const handleConfirm = (confirmed: boolean) => {
    setIsConfirmProfileAssignmentVisible((prev) => !prev);
    if (!confirmed) {
      return;
    }
    if (dontShowConfirmationDialog) {
      localStorage.setItem("setProfileConformation", "true");
    }
    if (profileInput) {
      mutateProfile({ input: profileInput });
    }
  };

  const onSubmit = async () => {
    // type of Assignment is not profile submit else ask for confirmation
    if (!isTypeOfAssignmentProfile) {
      const input: SetAssetSensorProfilesInput = {
        selectedImeis: selectedAssetsImeis,
        orgId: currentOrgId ?? "",
        sensors: {
          temperature: {
            internal: prepareDoubleRulesPayload(
              SensorProfileConfigType.Temperature,
              coldTemperature,
              hotTemperature,
              healthyPoint,
              min,
              max,
              "tempInternal"
            ) as SensorConfigInputTempInternal,
          },
        },
      };
      mutateCustomProfile({ input });
    } else {
      const selectedProfileId = mainForm.getValues().temperatureSensorProfile;
      const selectedProfile = temperatureSensorProfiles.find(
        (profile) => profile.id === selectedProfileId
      );

      setSelectedProfileLabel(selectedProfile?.label ?? "");
      const input: AssignOrgProfileToAssetsInput = {
        assetIds: selectedRowsIds,
        profileId: selectedProfileId ?? "",
      };
      // if we have dontShowConfirmationDialog again clicked
      // and submitted to local storage, don't show confirmation dialog
      if (dontShowConfirmationDialog) {
        mutateProfile({ input });
      } else {
        setProfileInput(input);
        setIsConfirmProfileAssignmentVisible((prev) => !prev);
      }
    }
  };

  const handleCheckboxClick = () => {
    setDontShowConfirmationDialog((prev) => !prev);
  };

  const selectedAssetsImeis: string[] = (selectedRecordsData ?? [])
    .map((obj) => obj?.imei)
    .filter((imei): imei is string => !!imei);

  const selectedRowsIds: string[] = (selectedRecordsData ?? [])
    .map((obj) => obj?._id)
    .filter((id): id is string => !!id);

  const selectedAssetsIds: string[] = (selectedRecordsData ?? [])
    .map((obj) => obj?.asset_id)
    .filter((id): id is string => !!id);

  const sensorProfilesForOrganization = useMemo(
    () => profilesData?.getSensorProfilesForOrganization ?? [],
    [profilesData]
  );
  const temperatureSensorProfiles: SensorProfileDropdownItemWithId[] =
    sensorProfilesForOrganization.map((item: any) => ({
      label: item.name,
      id: item._id,
      configuration: item.configuration,
      type: item.type,
    }));

  TEMPERATURE_FORM_FIELDS[1].options = sortSensorProfileNameDropdownItems(
    temperatureSensorProfiles as unknown as SensorProfileDropdownItem[]
  );

  const dynamicMinOrMax = returnDynamicLimit(
    healthyPoint,
    HEALTHY_POINT_MIN,
    HEALTHY_POINT_MAX
  );

  const isLoading = isLoadingProfile || isLoadingCustomProfile;
  const { isDirty, isValid } = mainForm.formState; // extract to subscribe to form state changes
  const isInvalid = isLoading || !isValid || !!healthyPointError;
  const isAnySliderFieldDirty =
    coldTemperature.toString() !== initialCold.toString() ||
    hotTemperature.toString() !== initialHot.toString() ||
    healthyPoint !== initialHealthy;
  const isCustomAndPristine =
    isAssetType && !isTypeOfAssignmentProfile && !isAnySliderFieldDirty;
  const isProfileAndPristine = !isAssetType && !isDirty;

  return (
    <Drawer
      isOpen={open}
      onRequestClose={handleClose}
      className="sm:!w-[518px] !w-full"
      hideHeader
      testId="temperature-sensor-drawer"
    >
      <DrawerHeader text="Edit Sensor" onClose={handleClose} />
      <DrawerContent>
        {selectedRecordsData && (
          <SelectedRecordInfo selectedRecordsData={selectedRecordsData} />
        )}
        <ThemeProvider theme={mainFormTheme}>
          <form>
            <Grid container className="thirdDrawerSection" direction="column">
              <Grid
                item
                data-testid="autocomplete-temperature-type-of-assignment"
              >
                <SelectElement
                  handleChange={handleAssignmentChange}
                  control={mainForm.control}
                  rules={{ required: true }}
                  label={TEMPERATURE_FORM_FIELDS[0].label}
                  name={TemperatureFormFieldNames.TypeOfAssignment}
                  options={TEMPERATURE_FORM_FIELDS[0].options}
                />
              </Grid>
              {isTypeOfAssignmentProfile && (
                <Grid item data-testid="autocomplete-temperature-profile">
                  <AutocompleteElement
                    loading={isSensorProfileNamesLoading}
                    autocompleteProps={{
                      onChange: handleSensorProfileInputChange,
                    }}
                    matchId={true}
                    control={mainForm.control}
                    rules={{ required: true }}
                    label={TEMPERATURE_FORM_FIELDS[1].label}
                    name={TemperatureFormFieldNames.TemperatureSensorProfile}
                    options={TEMPERATURE_FORM_FIELDS[1].options}
                  />
                </Grid>
              )}
            </Grid>
          </form>

          <Box className="pt-6 pb-10 flex-auto">
            <Grid
              container
              className="drawerSection px-6"
              spacing={6}
              direction="column"
            >
              <Grid item>
                <Text
                  fontSize={14}
                  fontWeight="bold"
                  classes="!text-base !text-primary"
                >
                  Temperature Settings
                </Text>
                {isLightTheme ? (
                  <TemperatureSensorDark
                    style={svgIconSettings}
                    data-testid="temperature-sensor-drawer-thermostat"
                  />
                ) : (
                  <TemperatureSensorLight
                    style={svgIconSettings}
                    data-testid="temperature-sensor-drawer-thermostat"
                  />
                )}
                <Input
                  value={healthyPoint}
                  onChange={onHealthyPointChange}
                  data-testid="temperature-sensor-drawer-healthy-point"
                  id="temperatureHealthyPointInput"
                  disabled={isLoading || isTypeOfAssignmentProfile}
                  sx={{ width: "100%" }}
                />
                {!!healthyPointError && (
                  <FormHelperText
                    data-testid="temperature-sensor-healthy-point-error-text"
                    error={true}
                  >
                    {healthyPointError}
                  </FormHelperText>
                )}
              </Grid>
              <Grid item>
                <Typography
                  sx={{
                    color: "var(--battery-settings)",
                    fontWeight: 800,
                    marginBottom: "4rem",
                  }}
                >
                  Hot Side Settings
                </Typography>
                <SensorSlider
                  values={hotTemperature}
                  min={dynamicMinOrMax}
                  max={max}
                  marks={sliderMarks(dynamicMinOrMax, max, "°F")}
                  disabled={isLoading || isTypeOfAssignmentProfile}
                  onChange={setHotTemperature}
                />
              </Grid>
              <Grid item>
                <Typography
                  sx={{
                    color: "var(--battery-settings)",
                    fontWeight: 800,
                    marginBottom: "4rem",
                  }}
                >
                  Cold Side Settings
                </Typography>
                <SensorSlider
                  values={coldTemperature}
                  min={min}
                  max={dynamicMinOrMax}
                  marks={sliderMarks(min, dynamicMinOrMax, "°F")}
                  disabled={isLoading || isTypeOfAssignmentProfile}
                  reversed
                  onChange={setColdTemperature}
                />
              </Grid>
            </Grid>
          </Box>
        </ThemeProvider>

        <DrawerFooter
          text={isLoading ? "Saving..." : "Apply to Selected"}
          disabled={isInvalid || isCustomAndPristine || isProfileAndPristine}
          testId="temperature-form-submit-button"
          submit={onSubmit}
        />
      </DrawerContent>
      {isTypeOfAssignmentProfile && isConfirmProfileAssignmentVisible && (
        <ConfirmationDialog
          title="Profile Assignment"
          message={`Warning, all of the sensor settings of profile ${selectedProfileLabel}
             will be applied to asset(s) ${selectedAssetsIds.join(
               ", "
             )}. Any custom sensor settings will be lost!`}
          open={isConfirmProfileAssignmentVisible}
          confirmButtonText={"Confirm"}
          cancelButtonText="Cancel"
          handleConfirmationResult={handleConfirm}
          additionalContent={
            <ConfigurationDialogAdditionalElement
              checked={dontShowConfirmationDialog}
              onChange={handleCheckboxClick}
            />
          }
        />
      )}
    </Drawer>
  );
};

export default TemperatureDrawer;
