import { UseFormReturn } from "react-hook-form";
import { ThemeProvider } from "@mui/material";
import { useAlertTheme } from "../../../AlertView/hooks/useAlertTheme";
import { AutomationForm } from "./AutomationForm";
import { AutomationInitialValues } from "./hooks/useAutomationForm";

interface AutomationSettingsProps {
  form: UseFormReturn<Partial<AutomationInitialValues>>;
  isTemplate: boolean;
}

export const AutomationSettings = ({
  form,
  isTemplate,
}: AutomationSettingsProps) => {
  const automationTheme = useAlertTheme();

  return (
    <ThemeProvider theme={automationTheme}>
      <AutomationForm form={form} isTemplate={isTemplate} />
    </ThemeProvider>
  );
};
