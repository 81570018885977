import { FC, useEffect, useState } from "react";
import { UseFormReturn, useWatch } from "react-hook-form";
import { AutocompleteElement } from "react-hook-form-mui";
import { Box, Grid, Paper, Typography } from "@mui/material";
import { useAppContext } from "../../../../context/AppContext";
import {
  AlertTypeV2,
  useFindAlertsV2ForAutomationsQuery,
} from "../../../../graphql/operations";
import { checkOptionEquality } from "../../../../utils";
import { AlertTypeTitle } from "../../../AlertsView/constants";
import { AutomationInitialValues } from "./hooks/useAutomationForm";

interface AutomationTriggerProps {
  form: UseFormReturn<Partial<AutomationInitialValues>>;
  isTemplate: boolean;
}
export const AutomationTrigger: FC<AutomationTriggerProps> = ({
  form,
  isTemplate,
}) => {
  const {
    state: {
      selectedOrganization: { selectedOrganization },
    },
  } = useAppContext();
  const [alertNameOptions, setAlertNameOptions] = useState<
    Array<{
      id: string;
      value: string;
      label: string;
    }>
  >([]);

  const alertTypeOptions = Object.values(AlertTypeTitle).map((value) => ({
    value: value.replaceAll(" ", "_"),
    id: value.replaceAll(" ", "_"),
    label: value,
  }));

  const alertTypeValue = useWatch({
    name: "parameters.alertType",
    control: form.control,
  });

  const {
    data: alertsForAutomations,
    isLoading: findAlertsForAutomationsLoading,
    isSuccess: findAlertsForAutomationsSuccess,
  } = useFindAlertsV2ForAutomationsQuery({
    input: {
      orgId: selectedOrganization.value,
      type: alertTypeValue as unknown as AlertTypeV2,
    },
  });

  useEffect(() => {
    if (
      alertsForAutomations &&
      !findAlertsForAutomationsLoading &&
      findAlertsForAutomationsSuccess
    ) {
      setAlertNameOptions(
        alertsForAutomations.findAlertsV2?.alerts?.map((alert) => ({
          id: alert._id,
          value: alert._id,
          label: alert.name,
        })) ?? []
      );
    }
  }, [
    alertsForAutomations,
    findAlertsForAutomationsLoading,
    findAlertsForAutomationsSuccess,
  ]);

  useEffect(() => {
    form.setValue("parameters.alertIds", []);
  }, [alertTypeValue, form]);

  return (
    <Paper elevation={0} className="mb-6">
      <Box className="p-12">
        <Typography className="!pb-8 !text-lg" variant="h6" component="div">
          Alert Trigger (Optional)
        </Typography>
        <Grid container spacing={6}>
          <Grid
            item
            xs={12}
            md={6}
            lg={6}
            xl={4}
            data-testid="automation-alert-type-select"
          >
            <AutocompleteElement
              matchId={true}
              label="Alert Type"
              control={form.control}
              name="parameters.alertType"
              textFieldProps={{
                placeholder:
                  "Select any Alert Type to filter the active alerts",
              }}
              autocompleteProps={{
                isOptionEqualToValue: checkOptionEquality,
                disabled: !isTemplate,
                readOnly: !isTemplate,
              }}
              options={alertTypeOptions}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            lg={6}
            xl={8}
            data-testid="automation-alert-names-select"
          >
            <AutocompleteElement
              matchId={true}
              multiple
              label="Alert Names"
              control={form.control}
              name="parameters.alertIds"
              textFieldProps={{
                placeholder: "Choose any active Alert",
              }}
              autocompleteProps={{
                isOptionEqualToValue: checkOptionEquality,
                disabled: !isTemplate,
                readOnly: !isTemplate,
              }}
              options={alertNameOptions ?? []}
            />
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};
